import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IAssignOutstandingFeedCharges,
    IAssignOutstandingFeedChargesFilters,
    ILots,
    IPens,
} from '../../utils/Interface/BillingInterface';
import { BILLING_INITIAL_STATE } from '../Constants/billingInitialState';

const billingSlice = createSlice({
    name: 'billing',
    initialState: { ...BILLING_INITIAL_STATE },
    reducers: {
        setBillingLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setFinalLoading: (state, action) => {
            state.finalLoading = action.payload;
        },
        setPastLoading: (state, action) => {
            state.pastLoading = action.payload;
        },
        setBillingFeedlotId: (state, action) => {
            state.feedlotId = action.payload;
        },
        setBillingPeriodSelection: (state, action) => {
            state.billingPeriodSelection = action.payload;
        },
        setPrevPeriodSelection: (state, action) => {
            state.prevPeriodSelection = action.payload;
        },
        setFinalInvoicePreview: (state, action) => {
            state.selectedInvoiceSummary = action.payload;
        },
        setInvoicePreview: (state, action) => {
            state.invoiceSummary = action.payload;
        },
        setAllBillingLots: (state, action) => {
            state.allLots = action.payload;
        },
        setAllNoCattleOpenLots: (state, action) => {
            state.allNoCattleOpenLots = action.payload;
        },
        setAllBillingLotsSummaries: (state, action) => {
            state.allLotsSummaries = action.payload;
        },
        setBillingPeriodInvoiceData: (state, action) => {
            state.billingPeriodInvoiceData = action.payload;
        },
        setCurrentBillingPeriodSummaries: (state, action) => {
            state.currentBillingPeriodSummaries = action.payload;
        },
        setBillingPeriodTotalAmount: (state, action) => {
            state.billingPeriodTotalAmount = action.payload;
        },
        setPastInvoices: (state, action) => {
            state.pastInvoices = action.payload;
        },
        setAllPastInvoices: (state, action) => {
            state.allPastInvoices = action.payload;
        },
        setFinalInvoiceShipRecords: (state, action) => {
            state.finalInvoiceShipRecords = action.payload;
        },
        setShipCattleDates: (state, action) => {
            state.shipCattleDates = action.payload;
        },
        setPreviousBillingDateRange: (state, action) => {
            state.previousBillingDateRange = action.payload;
        },
        setCurrentBillingDateRange: (state, action) => {
            state.currentBillingDateRange = action.payload;
        },
        setInvoicePdfDateRange: (state, action) => {
            state.invoicePdfDateRange = action.payload;
        },
        setPreviousBillingPeriods: (state, action) => {
            state.previousBillingPeriods = action.payload;
        },
        setAllPastSummaries: (state, action) => {
            state.allPastSummaries = action.payload;
        },
        setPens: (state, action: PayloadAction<IPens[]>) => {
            state.pens = action.payload;
        },
        setLots: (state, action: PayloadAction<ILots[]>) => {
            state.lots = action.payload;
        },
        setOpenBillingLots: (state, action: PayloadAction<ILots[]>) => {
            state.openBillingLots = action.payload;
        },
        setAssignOutstandingFeedChargesFilters: (
            state,
            action: PayloadAction<IAssignOutstandingFeedChargesFilters>,
        ) => {
            state.assignOutstandingFeedChargesFilters = action.payload;
        },
        setAssignOutstandingFeedCharges: (
            state,
            action: PayloadAction<IAssignOutstandingFeedCharges[]>,
        ) => {
            state.assignOutstandingFeedCharges = action.payload;
        },
        setAssignOutstandingFeedChargesCanCallApi: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.assignOutstandingFeedChargesCanCallApi = action.payload;
        },
        setLifeToDateSummary: (state, action) => {
            state.lifeToDateSummary = action.payload;
        },
        setMiscCharges: (state, action) => {
            state.miscCharges = action.payload;
        },
        appendMiscCharges: (state, action) => {
            state.miscCharges = [...state.miscCharges, ...action.payload];
        },
        setMiscChargesFilter: (state, action) => {
            state.miscChargesFilter = action.payload;
        },
        setMiscChargesLoading: (state, action) => {
            state.miscChargesLoading = action.payload;
        },
        setMiscChargesCanCallApi: (state, action) => {
            state.miscChargesCanCallApi = action.payload;
        },
        setNewMiscCharges: (state, action) => {
            state.newMiscCharges = action.payload;
        },
        setConfirmationActiveButton: (state, action) => {
            state.confirmationActiveButton = action.payload;
        },
        setMiscChargesActiveButton: (state, action) => {
            state.miscChargesActiveButton = action.payload;
        },
        setNewMiscChargesErrors: (state, action) => {
            state.newMiscChargesErrors = action.payload;
        },
        setPreviewingInvoicesLoading: (state, action) => {
            state.previewingInvoicesLoading = action.payload;
        },
        setGeneratingInvoicesLoading: (state, action) => {
            state.generatingInvoicesLoading = action.payload;
        },
        setAssignOutstandingFeedChargesSubmitPending: (state, action) => {
            state.assignOutstandingFeedChargesSubmitPending = action.payload;
        },
        resetBillingState: (state, action) => {
            return BILLING_INITIAL_STATE;
        },
        setIsQuickBooksLoading: (state, action) => {
            state.isQuickBooksLoading = action.payload;
        },
        updateQuickBooksStatus: (state, action) => {
            const updatedInvoices = action.payload;
            const invoiceMap = new Map(
                state.pastInvoices.map(invoice => [invoice.invoiceId, invoice]),
            );

            updatedInvoices.forEach(updatedInvoice => {
                const existingInvoice = invoiceMap.get(
                    updatedInvoice.invoiceId,
                );
                if (existingInvoice) {
                    existingInvoice.quickBooksStatus =
                        updatedInvoice.quickBooksStatus;
                }
            });
        },
    },
});

export const billingActions = billingSlice.actions;
const billingReducer = billingSlice.reducer;
export default billingReducer;
