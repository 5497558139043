import {
    IAssignOutstandingFeedCharges,
    IBillingCharge,
    IBillingLot,
    IBillingShipRecord,
    IExpectedDateRange,
    IFeedCharge,
    IInvoice,
    IInvoiceCharge,
    IToDateSummary,
    ILots,
    IPens,
    IMiscCharge,
    INewMiscChargesError,
    MiscChargesOptions,
    ConfirmationModalOptions,
    IMiscFilter,
    IAssignOutstandingFeedChargesFilters,
    IInvoicePDFRequestModel,
} from '../../utils/Interface/BillingInterface';
import { ILotSummary } from '../../utils/Interface/LotSummaryInterface';
import { IShipCattleDates } from '../../utils/Interface/ShipCattleInterface';
import { LotState } from '../../utils/Interface/LotInterface';

interface IBillingInitialState {
    loading: boolean;
    finalLoading: boolean;
    pastLoading: boolean;
    feedlotId: number | undefined;
    billingPeriodSelection: number;
    prevPeriodSelection: number;
    billingPeriodTotalAmount: number;
    pens: IPens[];
    lots: ILots[];
    openBillingLots: ILots[];
    baseCharge: IBillingCharge;
    previousBillingDateRange: IExpectedDateRange;
    currentBillingDateRange: IExpectedDateRange;
    invoicePdfDateRange: IExpectedDateRange;
    selectedInvoiceSummary: ILotSummary;
    invoiceSummary: IInvoicePDFRequestModel;
    allLots: IBillingLot[];
    allNoCattleOpenLots: IBillingLot[];
    allLotsSummaries: ILotSummary[];
    billingPeriodInvoiceData: ILotSummary[];
    currentBillingPeriodSummaries: ILotSummary[];
    pastInvoices: IInvoice[];
    allPastInvoices: IInvoice[];
    invoiceCharges: IInvoiceCharge;
    finalInvoiceShipRecords: IBillingShipRecord[];
    shipCattleDates: IShipCattleDates[];
    billingLot: IBillingLot;
    feedCharge: IFeedCharge;
    previousBillingPeriods: IExpectedDateRange[];
    allPastSummaries: IInvoice[];
    assignOutstandingFeedChargesCanCallApi: boolean;
    assignOutstandingFeedChargesFilters: IAssignOutstandingFeedChargesFilters;
    assignOutstandingFeedCharges: IAssignOutstandingFeedCharges[];
    lifeToDateSummary: IToDateSummary;
    miscChargesFilter: IMiscFilter;
    miscCharges: IMiscCharge[];
    miscChargesLoading: boolean;
    miscChargesCanCallApi: boolean;
    newMiscCharges: IMiscCharge[];
    miscChargesActiveButton: MiscChargesOptions;
    confirmationActiveButton: ConfirmationModalOptions;
    newMiscChargesErrors: INewMiscChargesError;
    previewingInvoicesLoading: boolean;
    generatingInvoicesLoading: boolean;
    assignOutstandingFeedChargesSubmitPending: boolean;
    isQuickBooksLoading: boolean;
}

export const BILLING_INITIAL_STATE: IBillingInitialState = {
    loading: false,
    finalLoading: false,
    pastLoading: false,
    feedlotId: undefined,
    miscChargesFilter: {
        limit: 20,
        offset: 0,
        amount: 0,
        miscChargeType: '',
        category: '',
        date: '',
        lotId: 0,
    },
    miscChargesLoading: false,
    miscChargesCanCallApi: true,
    billingPeriodSelection: -1,
    prevPeriodSelection: -1,
    billingPeriodTotalAmount: 0,
    pens: [{ name: 'Select Pen', penId: 0 }],
    lots: [{ name: 'Select Lot', id: 0, state: '' }],
    openBillingLots: [],
    //TODO Update/Delete to expected charges Values.
    baseCharge: {
        baseChargeId: 0,
        chargeInCents: 0,
        date: '',
        lotId: 0,
        chargeType: '',
        headCount: 0,
    },
    previousBillingDateRange: {
        startDate: '',
        endDate: '',
    },
    currentBillingDateRange: {
        startDate: '',
        endDate: '',
    },
    invoicePdfDateRange: {
        startDate: '',
        endDate: '',
    },
    invoiceSummary: {
        feedLot: {
            feedLotId: 0,
            label: '',
            manager: '',
            locationId: 0,
            yardageRate: 0,
        },
        dateIn: '',
        lot: {
            lotId: 0,
            name: '',
            hdCount: 0,
            totalHdCount: 0,
            hdCountReprocessed: 0,
            hdCountProcessed: 0,
            lotAverageWeight: 0,
            averageDailyGain: 0,
            feedLotId: 0,
            ownerId: 0,
            cattleTypeId: 0,
            sexId: 0,
            createdDatetime: '',
            state: LotState.Open,
        },
        owner: {
            id: 0,
            name: '',
            contactNumber: '',
            altContactNumber: '',
            email: '',
            addressId: 0,
            contactName: '',
            address1: '',
            postalCode: '',
        },
        currentHeadCount: {
            purchased: 0,
            sold: 0,
            dead: 0,
            realizers: 0,
            remaining: 0,
        },
        dateRange: {
            startDate: '',
            endDate: '',
        },
        yardageCharge: 0,
        statementDate: '',
        charges: {
            feedCharges: [],
            proccessingCharges: 0,
            medicalCharges: 0,
            yardageCharges: 0,
            cviBrandsCharges: 0,
            miscCharges: {
                miscTotal: 0,
                miscFeed: 0,
                miscBedding: 0,
                miscProccessing: 0,
                miscMedicine: 0,
                miscYardage: 0,
                miscBrandsCVI: 0,
                miscOther: 0,
            },
            miscCredits: {
                miscTotal: 0,
                miscFeed: 0,
                miscBedding: 0,
                miscProccessing: 0,
                miscMedicine: 0,
                miscYardage: 0,
                miscBrandsCVI: 0,
                miscOther: 0,
            },
        },
        periodToDate: {
            totalCost: 0,
            totalPoundsFed: 0,
            totalHdDays: 0,
            avgDailyCost: 0,
            avgDailyConsumption: 0,
        },
        lifetimeToDate: {
            totalCost: 0,
            totalPoundsFed: 0,
            totalHdDays: 0,
            avgDailyCost: 0,
            avgDailyConsumption: 0,
        },
        isPreview: true,
    },
    selectedInvoiceSummary: {
        currentHeadCount: {
            dead: 0,
            purchased: 0,
            realizers: 0,
            remaining: 0,
            sold: 0,
        },
        dateIn: '',
        fmFeedLot: {
            feedLotId: 0,
            label: '',
            manager: '',
            location: {
                altContactNumber: '',
                altEmail: '',
                contactName: '',
                contactNumber: '',
                email: '',
                name: '',
                address1: '',
                address2: '',
                city: '',
                coordinates: '',
                country: '',
                postalCode: '',
                state: '',
            },
        },
        fmLot: {
            averageDailyGain: 0,
            cattleTypeId: 0,
            createdDatetime: '',
            feedLotId: 0,
            cattleType: {
                cattleTypeId: 0,
                name: '',
                description: '',
            },
            feedlot: {
                feedLotId: 0,
                label: '',
                manager: '',
                locationId: 0,
                yardageRate: 0,
            },
            hdCount: 0,
            hdCountProcessed: 0,
            hdCountReprocessed: 0,
            lotId: 0,
            name: '',
            owner: {
                addressId: 0,
                altContactNumber: '',
                contactName: '',
                contactNumber: '',
                email: '',
                id: 0,
                name: '',
            },
            ownerId: 0,
            sex: {
                name: '',
                id: 0,
            },
            sexId: 0,
            totalHdCount: 0,
            lotAverageWeight: 0,
            state: LotState.Open,
        },
        owner: {
            address1: '',
            address2: '',
            altContactNumber: '',
            city: '',
            contactName: '',
            contactNumber: '',
            coordinates: '',
            country: '',
            email: '',
            name: '',
            postalCode: '',
            state: '',
        },
        statementDate: '',
        yardageCharge: 0,
        charges: {
            feedCharges: [],
            processingCharges: 0,
            medicalCharges: 0,
            yardageCharges: 0,
            cviBrandsCharges: 0,
            miscCharges: {
                miscTotal: 0,
                subcharges: {
                    miscFeed: 0,
                    miscBedding: 0,
                    miscProcessing: 0,
                    miscMedicine: 0,
                    miscYardage: 0,
                    miscBrandsCVI: 0,
                    miscOther: 0,
                },
            },
            miscCredits: {
                miscTotal: 0,
                subcharges: {
                    miscFeed: 0,
                    miscBedding: 0,
                    miscProcessing: 0,
                    miscMedicine: 0,
                    miscYardage: 0,
                    miscBrandsCVI: 0,
                    miscOther: 0,
                },
            },
        },
    },
    allLots: [],
    allNoCattleOpenLots: [],
    billingPeriodInvoiceData: [],
    currentBillingPeriodSummaries: [],
    allLotsSummaries: [],
    pastInvoices: [],
    allPastInvoices: [],
    invoiceCharges: {
        feedCharges: [],
        processingCharges: 0,
        medicalCharges: 0,
        yardageCharges: 0,
        cviBrandsCharges: 0,
        miscCharges: {
            miscTotal: 0,
            subcharges: {
                miscFeed: 0,
                miscBedding: 0,
                miscProcessing: 0,
                miscMedicine: 0,
                miscYardage: 0,
                miscBrandsCVI: 0,
                miscOther: 0,
            },
        },
        miscCredits: {
            miscTotal: 0,
            subcharges: {
                miscFeed: 0,
                miscBedding: 0,
                miscProcessing: 0,
                miscMedicine: 0,
                miscYardage: 0,
                miscBrandsCVI: 0,
                miscOther: 0,
            },
        },
    },
    finalInvoiceShipRecords: [],
    shipCattleDates: [],
    billingLot: {
        lotId: 0,
        name: '',
        hdCount: 0,
        totalHdCount: 0,
        hdCountReprocessed: 0,
        hdCountProcessed: 0,
        lotAverageWeight: 0,
        averageDailyGain: 0,
        feedLotId: 0,
        ownerId: 0,
        cattleTypeId: 0,
        sexId: 0,
        createdDatetime: '',
        state: LotState.Open,
    } as IBillingLot,
    feedCharge: {
        rationId: 0,
        rationName: '',
        rationFeedAmount: 0,
        rationChargeTotal: 0,
        costPerTonWithMarkup: 0,
    },
    previousBillingPeriods: [],
    allPastSummaries: [],
    assignOutstandingFeedChargesCanCallApi: true,
    assignOutstandingFeedChargesFilters: {
        include:
            'PenRationHistory.FeedCall,PenRationHistory.Ration,PenRationHistory.Pen',
        limit: 20,
        offset: 0,
        penId: 0,
    },
    assignOutstandingFeedCharges: [],
    lifeToDateSummary: {
        totalCost: 0,
        totalPoundsFed: 0,
        totalHdDays: 0,
        avgDailyCost: 0,
        avgDailyConsumption: 0,
    } as IToDateSummary,
    miscCharges: [],
    newMiscCharges: [
        {
            date: '',
            lotName: '0',
            category: 'Charge',
            miscChargeType: '',
            amount: 0,
            notes: '',
            cutoffDate: '',
            isInGeneratedPeriod: true,
        },
    ],
    miscChargesActiveButton: MiscChargesOptions.NoneSelected,
    confirmationActiveButton: ConfirmationModalOptions.NoneSelected,
    newMiscChargesErrors: {
        0: {
            lotId: false,
            lotName: false,
            category: false,
            miscChargeType: false,
            amount: false,
        },
    },
    previewingInvoicesLoading: false,
    generatingInvoicesLoading: false,
    assignOutstandingFeedChargesSubmitPending: false,
    isQuickBooksLoading: false,
};
