import { IFeedCallRoutesInitialState } from '../interfaces/FeedCallRoutesInterface';
/**
 * Create Feed Call Routes initial states
 */
export const FEED_CALL_ROUTES_INITIAL_STATE: IFeedCallRoutesInitialState = {
    feedCallRoutes: [],
    feedCallRoutesStaticRow: {
        ordinal: 0,
        pen: {
            currentFeedingAmount: 0,
            currentFeedingNumber: 0,
            currentRationAmount: 0,
            currentRationId: 0,
            currentTotalCall: 0,
            feedLotId: 0,
            feedTaskId: null,
            feedlot: null,
            hdCount: 0,
            kind: '',
            penId: 0,
            name: '',
            penRationHistory: [],
            penRationSchedule: null,
            penRationScheduleId: 0,
        },
        penId: 0,
        backGround: '',
    },
    feedCallRoutesPens: [],
    loading: true,
    defaultFeedCallRoutes: [],
    submitPending: false,
    isPensReverseFeedCallOrder: false,
    reverseFeedCallRouteFmSettingId: null,
};
