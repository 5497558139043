import { apiCall, apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreatePen,
    IDeletePen,
    IFetchPenDetails,
    IUpdatePenDetail,
    IUpdatePenDetailOptions,
} from '../../utils/Interface/PenInterface';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from '../Reducer/common';
import { setPenDetailsLoader } from '../Reducer/penReducer';
import { getFilterUrl } from './FMSettingActions';
import { AppDispatch, AppThunkDispatch } from '../Store';

export const fetchPenDetails: IFetchPenDetails =
    (feedlotId, setPenDetail, penFilter) => async (dispatch: AppDispatch) => {
        dispatch(setPenDetailsLoader(true));
        let filterUrl = '';
        if (penFilter) {
            filterUrl = getFilterUrl(penFilter, 'active');
            if (!penFilter.show || penFilter.show === 'all') {
                filterUrl += '&penStatus=all';
            }
        }
        const response = await apiCall(
            'get',
            `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/odata?flid=${feedlotId}${filterUrl}&orderBy=Name`,
        );
        setPenDetail(response);
        dispatch(setPenDetailsLoader(false));
    };

export const createPen: ICreatePen =
    (feedlotId, pen, moveToPenDetails) =>
    async (dispatch: AppThunkDispatch) => {
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}`,
            payload: pen,
            isResRequired: true,
        });

        if (!response) return;
        const status = Number(response.status);
        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            dispatch(toastMessage(getToastContent(status)));
            moveToPenDetails();
            return;
        } else if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.PEN_DUPLICATE,
                    ),
                ),
            );
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
        return response;
    };

export const updatePenDetail: IUpdatePenDetail =
    (feedlotId, penDetail, options?: IUpdatePenDetailOptions) =>
    async (dispatch: AppDispatch) => {
        options?.setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/${penDetail.penId}`,
            payload: penDetail,
            isResRequired: true,
        });

        options?.setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.PEN_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            options?.handleActivatePen?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            options?.closeModalWithChanges?.(true);
        }
        options?.fetchPenData?.();
    };

export const fetchInactivePenInfo =
    (feedlotId: number, pen?: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/odata?filter=name eq '${pen}'`,
            isResRequired: true,
        });
        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deletePen: IDeletePen =
    (feedlotId, pen, options?) => async (dispatch: AppDispatch) => {
        dispatch(setPenDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/${pen.penId}`,
            payload: pen,
            isResRequired: true,
        });
        dispatch(setPenDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        options?.fetchPenData?.();
    };
