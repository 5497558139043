import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../Redux/Store';
import {
    CattleFinanceManagerRoleEnum,
    FeedlotManagerRoleEnum,
    ShippingTrackerRoleEnum,
    UserStatusEnum,
} from '../interfaces/UserManagementInterface';
import { usersApi } from '../../../../../../Redux/Apis/Users/usersApi';
import {
    accountsApi,
    getApiTmAccountsResponse,
} from '../../../../../../Redux/Apis/Accounts/accountsApi';
import { TenantManagementModelsUserManagementUserModelRead } from '../../../../../../Redux/Apis/Users/baseUsersApi';
import useAuthContext from '../../../../../common/modules/auth/hooks/useAuthContext';
import { deepClone, sortAlphaNumeric } from '../../../../../../helpers';

export const useGetQueryArgs = () => {
    const { t } = useTranslation();

    const { userManagementFilter } = useAppSelector(
        state => state.userManagement,
    );

    const getUserQueryString = () => {
        const filterConditions = [
            ...(userManagementFilter.name
                ? [
                      `contains(concat(concat(FirstName, ' '), LastName), '${userManagementFilter.name}')`,
                  ]
                : []),
            ...(userManagementFilter.email
                ? [`contains(Username, '${userManagementFilter.email}')`]
                : []),
            ...(+userManagementFilter.accountId
                ? [
                      `(Accounts/any(account:account/AccountId eq ${userManagementFilter.accountId}) or Accounts/any() eq false)`,
                  ]
                : []),
            ...(userManagementFilter.app &&
            userManagementFilter.app !==
                t('userManagement:userManagementLabels.staticOptions.selectApp')
                ? [
                      `Scopes/any(scope:scope/name eq 'APP:${userManagementFilter.app.replace(/\s/g, '')}')`,
                  ]
                : []),
            ...(userManagementFilter.status !== null
                ? [`Enabled eq ${userManagementFilter.status}`]
                : []),
        ];

        const filterClause = filterConditions.filter(Boolean).join(' and ');
        return filterClause || undefined;
    };

    const roleQuery =
        userManagementFilter.role &&
        userManagementFilter.role !==
            t('userManagement:userManagementLabels.staticOptions.selectRole')
            ? `${userManagementFilter.role.replace(/\s/g, '')}`
            : undefined;

    const queryArgs = {
        include: 'Roles,Scopes,Accounts.Roles',
        filter: getUserQueryString(),
        orderBy: 'FirstName,LastName',
        top: userManagementFilter.limit,
        skip: userManagementFilter.offset,
        role: roleQuery,
    };
    return queryArgs;
};

const filterApps = apps => {
    return apps.filter(app => app.name.startsWith('APP:'));
};

const formatAppName = (appName: string) => {
    return appName.replace('APP:', '').replace(/(?<!^)([A-Z])/g, ' $1');
};

const spaceRoleName = (appName: string) => {
    const result = appName.replace(/(?<!^)([A-Z])/g, ' $1');
    return result;
};

const getGeneralRoles = (
    user: TenantManagementModelsUserManagementUserModelRead,
    validAppRoles,
) => {
    return (
        user.roles
            ?.filter(role =>
                validAppRoles.some(
                    appRole =>
                        role.name && appRole === spaceRoleName(role.name),
                ),
            )
            ?.map(role => role.name) ?? []
    );
};

const getAccountRoles = (
    user: TenantManagementModelsUserManagementUserModelRead,
    validAppRoles,
    accessibleAccounts: getApiTmAccountsResponse[],
    selectedAccountId: number,
) => {
    return (
        user.accounts
            ?.filter(account =>
                selectedAccountId
                    ? account.accountId === selectedAccountId
                    : accessibleAccounts.some(
                          accessibleAccount =>
                              accessibleAccount.accountId === account.accountId,
                      ),
            )
            ?.map(account =>
                account?.roles
                    ?.filter(role =>
                        validAppRoles.some(
                            appRole =>
                                role.name &&
                                appRole === spaceRoleName(role.name),
                        ),
                    )
                    ?.map(role => `${account?.account?.name} - ${role.name}`),
            ) ?? []
    );
};

export const useGetUsers = ({
    apps,
    accessibleAccounts,
}: {
    apps: string[];
    accessibleAccounts: getApiTmAccountsResponse[];
}) => {
    const { userManagementFilter } = useAppSelector(
        state => state.userManagement,
    );

    const { data, isFetching } =
        usersApi.useGetApiTmUsersOdataQuery(useGetQueryArgs());

    const roleEnums = {
        'Cattle Finance Manager': CattleFinanceManagerRoleEnum,
        'Feedlot Manager': FeedlotManagerRoleEnum,
        'Shipping Tracker': ShippingTrackerRoleEnum,
    };

    const formatUserData = () => {
        if (data) {
            const validAppRoles = userManagementFilter.app
                ? Object.values(roleEnums[userManagementFilter.app] || [])
                : apps.map(app => Object.values(roleEnums[app] || [])).flat();

            const formattedUsers =
                data.map(user => {
                    const generalRoles = getGeneralRoles(user, validAppRoles);
                    const accountRoles = getAccountRoles(
                        user,
                        validAppRoles,
                        accessibleAccounts,
                        +userManagementFilter.accountId,
                    );
                    const roles = [...generalRoles, ...accountRoles];
                    const apps = filterApps(user.scopes).map(app => {
                        return formatAppName(app.name);
                    });
                    return {
                        name: user.fullName,
                        email: user.username,
                        apps,
                        roles,
                        status: user.enabled
                            ? UserStatusEnum.Active
                            : UserStatusEnum.Inactive,
                        resetPassword: false,
                        editUser: false,
                    };
                }) ?? [];

            return formattedUsers;
        }
        return [];
    };

    return { users: formatUserData(), isFetching };
};

export const useGetDataForFilterDropdowns = () => {
    const { getUserId } = useAuthContext();

    const allTenantAccounts = accountsApi.useGetApiTmAccountsQuery({}).data;

    const myUserData = usersApi.useGetUserDataQuery({
        include: 'Roles,Scopes,Accounts.Roles',
        filter: `UserId eq ${getUserId()}`,
        top: 1,
    })?.data?.[0];

    const getApps = (): string[] => {
        return myUserData?.scopes
            ? sortAlphaNumeric(deepClone(myUserData)?.scopes, 'name')
                  ?.filter(scope => scope.name.includes('APP:'))
                  ?.map(scope => formatAppName(scope.name))
            : [];
    };

    const getAccounts = () => {
        if (myUserData?.accounts?.length) {
            return (
                allTenantAccounts?.filter(account =>
                    myUserData.accounts?.some(
                        myAccount => myAccount.accountId === account.accountId,
                    ),
                ) ?? []
            );
        } else {
            return allTenantAccounts || [];
        }
    };

    return { apps: getApps(), accounts: getAccounts() };
};
