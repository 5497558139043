import { isLocalEnvironment } from '../../../utils/isLocalEnv';

export const getFeatureFlagValue = (
    envVariableValue: string | undefined,
): boolean => {
    if (envVariableValue === 'on') {
        return true;
    } else if (envVariableValue === 'off') {
        return false;
    } else {
        return isLocalEnvironment;
    }
};
