import { formatDate } from '../../helpers';
import dayjs from '../../utils/dayjs';

interface IDashboardInitialState {
    dashboardRefreshDate: string;
}

export const DASHBOARD_INITIAL_STATE: IDashboardInitialState = {
    dashboardRefreshDate: formatDate(dayjs(), 'MMMM D, YYYY h:mm A z'),
};
