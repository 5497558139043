import { financeManagerFeatures } from '../../../featureFlags/financeManagerFeatures';

const { isFA437TenantSetting } = financeManagerFeatures;
export const settingsDataTypes = {
    initialEquity: 'CCCFinancialApp.Data.Entities.InitialEquity',
    minimumEquity: 'CCCFinancialApp.Data.Entities.MinimumEquity',
    advanceRate: 'CCCFinancialApp.Data.Entities.AdvanceRate',
    averageDailyGain: 'CCCFinancialApp.Data.Entities.AverageDailyGain',
    spread: 'CCCFinancialApp.Data.Entities.Spread',
    originationFeePerHead:
        'CCCFinancialApp.Data.Entities.OriginationFeePerHead',
    hedgeFeePerHead: 'CCCFinancialApp.Data.Entities.HedgeFeePerHead',
    holsteinDiscount: 'CCCFinancialApp.Data.Entities.HolsteinDiscount',
} as const;

export const getSettingByKey = (key: keyof typeof settingsDataTypes) => {
    return settingsDataTypes[key];
};

export type SettingsDataType =
    (typeof settingsDataTypes)[keyof typeof settingsDataTypes];

export const SettingsTitles = {
    initialEquity: 'INITIAL EQUITY :',
    minimumEquity: 'MINIMUM EQUITY :',
    advanceRate: 'ADVANCE RATE :',
    averageDailyGain: 'AVERAGE DAILY GAIN :',
    spread: 'SPREAD :',
    originationFeePerHead: 'ORIGINATION FEE :',
    hedgeFeePerHead: 'HEDGE FEE :',
    holsteinDiscount: 'HOLSTEIN DISCOUNT :',
};

export const EditSettingForm = {
    initialEquity: 'Initial Equity',
    ownerName: 'Owner Name',
    minimumEquity: 'Minimum Equity',
    advanceRate: 'Advance Rate',
    averageDailyGain: 'Average Daily Gain',
    spread: 'Spread',
    originationFeePerHead: 'Origination Fee Per Head',
    hedgeFeePerHead: 'Hedge Fee Per Head',
    holsteinDiscount: 'Holstein Discount',
    effectiveDate: 'Effective Date',
    title: 'Edit Settings',
    rateChange: 'Rate Change',
    placeholders: {
        initialEquity: 'Enter Initial Equity',
        minimumEquity: 'Enter Minimum Equity',
        advanceRate: 'Enter Advance Rate',
        averageDailyGain: 'Enter Average Daily Gain',
        spread: 'Enter Spread',
        originationFeePerHead: 'Enter Origination Fee Per Head',
        hedgeFeePerHead: 'Enter Hedge Fee Per Head',
        holsteinDiscount: 'Enter Holstein Discount',
        effectiveDate: 'Select Effective Date',
    },
};

export const SettingOptions = [
    { value: 'initialEquity', label: 'Initial Equity' },
    { value: 'minimumEquity', label: 'Minimum Equity' },
    { value: 'advanceRate', label: 'Advance Rate' },
    { value: 'originationFeePerHead', label: 'Origination Fee' },
    { value: 'hedgeFeePerHead', label: 'Hedge Fee' },
    { value: 'spread', label: 'Spread' },
];

export const HistoricalSettingConstants = {
    settingOptions: [...SettingOptions],

    historicalTableLabel: [
        { value: 'effectiveDate', label: 'Effective Date' },
        { value: 'rate', label: 'Rate' },
        { value: 'user', label: 'User' },
    ],

    recordNotFoundError: ' No records found for this setting type',
    filterSettingType: 'Filter by setting types',
    owner: 'Owner',
};

export const SettingPageButton = {
    clearFilter: 'Clear Filters',
};

export const OwnerSettingTabConstants = {
    owner: 'Owner',
    ownerSettingTableLabels: [
        { value: 'owner', label: 'Owner' },
        { value: 'type', label: 'Type' },
        { value: 'initialEquity', label: 'Init. Equity' },
        { value: 'minimumEquity', label: 'Min Equity' },
        { value: 'advanceRate', label: 'Adv. Rate' },
        { value: 'averageDailyGain', label: 'ADG' },
        { value: 'spread', label: 'Spread' },
        { value: 'originationFeePerHead', label: 'Orig Fee' },
        { value: 'hedgeFeePerHead', label: 'Hedge Fee' },
        { value: 'holsteinDiscount', label: 'Holstein Disc' },
        { value: 'actions', label: '' },
        ...(isFA437TenantSetting ? [{ value: 'actions', label: '' }] : []),
    ],
};

export const FormFeedbackMessages = {
    looksGood: 'Looks good!',
    pleaseSelectDate: 'Please select a date.',
    pleaseProvideRate: 'Please provide a rate setting.',
};

export const editSettingForm = {
    initialEquity: 'Initial Equity',
    minimumEquity: 'Minimum Equity',
    advanceRate: 'Advance Rate',
    averageDailyGain: 'Average Daily Gain',
    spread: 'Spread',
    originationFeePerHead: 'Origination Fee Per Head',
    hedgeFeePerHead: 'Hedge Fee Per Head',
    holsteinDiscount: 'Holstein Discount',
};

export const DeleteModal = {
    deactivateOwnerMessage:
        'You cannot deactivate this Owner because they have active lots associated. Please ensure all lots are closed before proceeding.',
    deleteOwnerMessage:
        'You cannot delete this Owner because they have associated records.',
    deleteOwnerTitle: 'Delete Owner',
    deactivateOwnerTitle: 'Deactivate Owner',
    deleteConfirmationMessage: 'Are you sure you want to delete this Owner?',
    deleteConfirmationTitle: 'Delete·Confirmation',
};
