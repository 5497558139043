import {
    Action,
    configureStore,
    createDraftSafeSelector,
    createSelector,
    ThunkDispatch,
} from '@reduxjs/toolkit';
//eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import rootReducer from '../Reducer/index';
import { rootApi } from '../Apis/rootApi';
import { isLocalEnvironment } from '../../utils/isLocalEnv';
import { listenerMiddleware } from '../listenerMiddleware';

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: isLocalEnvironment,
                immutableCheck: isLocalEnvironment,
                actionCreatorCheck: isLocalEnvironment,
            })
                .prepend(listenerMiddleware.middleware)
                .concat(rootApi.middleware),
        devTools: isLocalEnvironment,
        preloadedState,
    });

const store = setupStore();

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type GetState = () => RootState;
export const useAppSelector = useSelector.withTypes<RootState>();

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunkDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export const createTypedSelector = createSelector.withTypes<RootState>();
export const createTypedDraftSafeSelector =
    createDraftSafeSelector.withTypes<RootState>();
