import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateOwner,
    IDeleteOwner,
    IFetchLots,
    IFetchOwnerDetails,
    IUpdateOwnerDetail,
    IUpdateOwnerDetailOptions,
} from '../../utils/Interface/OwnerInterface';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from '../Reducer/common';
import { setOwnerDetailsLoader } from '../Reducer/ownerSettingSlice';
import { AppDispatch } from '../Store';
import { getFilterUrl } from './FMSettingActions';

export const fetchOwnerDetails: IFetchOwnerDetails =
    (setOwnerDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setOwnerDetailsLoader(true));
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(
                filter,
                'active',
                filter.ownerId ? `id eq ${filter.ownerId}` : '',
            );
        }
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.COMPANY_OWNERS}/odata?${filterUrl}&orderBy=Name&include=address,associatedlots`,
        });
        setOwnerDetail(response ?? []);
        dispatch(setOwnerDetailsLoader(false));
    };

export const updateOwnerDetail: IUpdateOwnerDetail =
    (ownerDetail, options?: IUpdateOwnerDetailOptions) =>
    async (dispatch: AppDispatch) => {
        options?.setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.COMPANY_OWNERS}/${ownerDetail.id}`,
            payload: ownerDetail,
            isResRequired: true,
        });

        options?.setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.OWNER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            options?.handleActivateOwner?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            options?.closeModalWithChanges?.(true, ownerDetail.id);
        }
        options?.fetchOwnerData?.();
    };

export const createOwner: ICreateOwner =
    (owner, closeModalWithChanges, setLoading, handleActivateOwner) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.COMPANY_OWNERS}`,
            payload: owner,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.OWNER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateOwner();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true, response.data);
        }
    };

export const fetchInactiveOwnerInfo =
    (owner: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.COMPANY_OWNERS}/odata?filter=name eq '${owner}'`,
            isResRequired: true,
        });
        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteOwner: IDeleteOwner =
    (owner, options?: IUpdateOwnerDetailOptions) =>
    async (dispatch: AppDispatch) => {
        dispatch(setOwnerDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.COMPANY_OWNERS}/${owner.id}`,
            payload: owner,
            isResRequired: true,
        });
        dispatch(setOwnerDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        options?.fetchOwnerData?.();
    };

export const fetchLots: IFetchLots = async (setLotDetail, feedlotId) => {
    const response = await apiCall_v2({
        method: 'get',
        url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOTS}`,
    });
    setLotDetail(response ?? []);
};
