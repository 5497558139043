import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLoading } from '../../commonHooks/useLoading';
import { useAppDispatch } from '../../Redux/Store';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { truckingCompanyConstants } from '../../utils/constants/TruckingCompanyConstant';
import {
    ITruckingCompanyDetail,
    ITruckingCompanyDetailsError,
    ITruckingCompanyModal,
} from '../../utils/Interface/TruckingCompanyInterface';
import {
    createTruckingCompany,
    fetchInactiveTruckingCompanyInfo,
    updateTruckingCompanyDetail,
} from '../../Redux/Actions/truckingCompanyActions';
import FullPageLoader from '../common/FullPageLoader';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { TRUCKING_COMPANY_INITIAL_STATE } from '../../Redux/Constants/truckingCompanyInitialState';

const TruckingCompanyModal = ({
    showModal,
    closeModalWithChanges,
    editTruckingCompanyDetails,
    showActiveField,
}: ITruckingCompanyModal) => {
    const [truckingCompanyDetail, setTruckingCompanyDetail] =
        useState<ITruckingCompanyDetail>(editTruckingCompanyDetails);
    const [truckingCompanyDetailsError, setTruckingCompanyDetailsError] =
        useState<ITruckingCompanyDetailsError>(
            truckingCompanyConstants.initialCompanyDetailsError,
        );
    const [inActiveTruckingCompany, setInActiveTruckingCompany] =
        useState<ITruckingCompanyDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();

    const validateForm = (
        truckingCompanyErrorCopy: ITruckingCompanyDetailsError,
    ) => {
        let isFormValid = true;
        const validateFields = ['postalCode', 'coordinates'];
        const validateTypes = ['zipcode', 'coordinates'];

        validateFields.forEach((validateField, index) => {
            const isValid = validateAccordingType(
                validateTypes[index],
                truckingCompanyDetail[validateField],
            );
            truckingCompanyErrorCopy[validateField] = !isValid;
            if (!isValid) {
                isFormValid = false;
            }
        });

        setTruckingCompanyDetailsError(truckingCompanyErrorCopy);

        return isFormValid;
    };

    const validateTruckingCompanyDetails = (): boolean => {
        let isValid = true;
        const { name, email, contactName, contactNumber } =
            truckingCompanyDetail;
        const updatedTruckingCompanyDetailErrors = {
            name: !name,
            email: !email,
            contactName: !contactName,
            contactNumber: !contactNumber,
        };
        setTruckingCompanyDetailsError(updatedTruckingCompanyDetailErrors);
        const isFormValid = validateForm(updatedTruckingCompanyDetailErrors);
        if (
            Object.values(updatedTruckingCompanyDetailErrors).includes(true) ||
            !isFormValid
        ) {
            isValid = false;
        }
        return isValid;
    };

    const handleActivateTruckingCompany = async () => {
        const inactiveTruckingCompanyInfo = await dispatch(
            fetchInactiveTruckingCompanyInfo(
                truckingCompanyDetail.name?.trim(),
            ),
        );
        if (inactiveTruckingCompanyInfo) {
            setInActiveTruckingCompany(inactiveTruckingCompanyInfo[0]);
            openActivateModal();
        }
    };

    const handleUpdateTruckingCompanyDetail = (): void => {
        const isValid = validateTruckingCompanyDetails();
        if (isValid) {
            dispatch(
                updateTruckingCompanyDetail(
                    truckingCompanyDetail,
                    undefined,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTruckingCompany,
                ),
            );
        }
    };

    const handleCreateTruckingCompany = (): void => {
        const isValid = validateTruckingCompanyDetails();
        if (isValid) {
            dispatch(
                createTruckingCompany(
                    truckingCompanyDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTruckingCompany,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setTruckingCompanyDetail(prevTruckingCompanyDetail => ({
                ...prevTruckingCompanyDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated) {
            if (inActiveTruckingCompany) {
                const inActiveTruckingCompanyCopy = {
                    ...inActiveTruckingCompany,
                    active: true,
                };
                dispatch(
                    updateTruckingCompanyDetail(
                        inActiveTruckingCompanyCopy,
                        undefined,
                        closeModalWithChanges,
                        setLoading,
                    ),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (!showModal) {
            setTruckingCompanyDetail({
                ...TRUCKING_COMPANY_INITIAL_STATE.createTruckingCompanyInitial,
            });
        }
    }, [showModal]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={truckingCompanyDetail.name?.trim()}
                item={truckingCompanyConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editTruckingCompanyDetails.name
                            ? `${truckingCompanyConstants.editTruckingCompany} ${editTruckingCompanyDetails?.name}`
                            : truckingCompanyConstants.createNewTruckingCompany
                                  .heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.name
                                        .label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    truckingCompanyDetailsError.name
                                        ? 'danger'
                                        : ''
                                }`}
                                value={truckingCompanyDetail?.name}
                                name={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.name.name
                                }
                                placeholder={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.name
                                        .placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.name
                                        .maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        {showActiveField && (
                            <div className="custom-checkbox col-5">
                                <input
                                    type="checkbox"
                                    checked={truckingCompanyDetail.active}
                                    className="mx-2"
                                    onChange={e =>
                                        handleDetailChange(
                                            !truckingCompanyDetail.active,
                                            'active',
                                        )
                                    }
                                />
                                <label className="mx-2">
                                    {FmSettingsConstant.active.label}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top my-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .contactName.label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    truckingCompanyDetailsError.contactName
                                        ? 'danger'
                                        : ''
                                }`}
                                value={truckingCompanyDetail?.contactName}
                                name={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .contactName.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .contactName.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top my-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .altContactName.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={truckingCompanyDetail?.altContactName}
                                name={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .altContactName.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .altContactName.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.email
                                        .label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    truckingCompanyDetailsError.email
                                        ? 'danger'
                                        : ''
                                }`}
                                value={truckingCompanyDetail?.email}
                                name={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.email
                                        .name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.email
                                        .maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.altEmail
                                        .label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={truckingCompanyDetail?.altEmail}
                                name={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.altEmail
                                        .name
                                }
                                placeholder={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.altEmail
                                        .placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form.altEmail
                                        .maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .contactNumber.label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    truckingCompanyDetailsError.contactNumber
                                        ? 'danger'
                                        : ''
                                }`}
                                value={truckingCompanyDetail?.contactNumber}
                                name={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .contactNumber.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .contactNumber.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .altContactNumber.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={truckingCompanyDetail?.altContactNumber}
                                name={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .altContactNumber.name
                                }
                                placeholder={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .altContactNumber.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    truckingCompanyConstants
                                        .createNewTruckingCompany.form
                                        .altContactNumber.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editTruckingCompanyDetails.name
                                ? handleCreateTruckingCompany
                                : handleUpdateTruckingCompanyDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TruckingCompanyModal;
