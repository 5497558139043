import { createSlice } from '@reduxjs/toolkit';
import { USER_MANAGEMENT_INITIAL_STATE } from '../constants/userManagementInitialState';

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState: USER_MANAGEMENT_INITIAL_STATE,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        appendUsers: (state, action) => {
            state.users = [...state.users, ...action.payload];
        },
        setFeedlots: (state, action) => {
            state.feedlots = action.payload;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        setApps: (state, action) => {
            state.apps = action.payload;
        },
        setUserManagementFilter: (state, action) => {
            state.userManagementFilter = action.payload;
        },
    },
});

export const userManagementActions = userManagementSlice.actions;

const userManagementReducer = userManagementSlice.reducer;
export default userManagementReducer;
