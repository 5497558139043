import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import es from './locales/es';
import { isLocalEnvironment } from '../utils/isLocalEnv';

export const i18nResources = {
    en,
    es,
} as const;

const ns = [...Object.keys(en)] as const;

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: isLocalEnvironment,
    fallbackLng: 'en',
    ns,
    resources: i18nResources,
});

export default i18n;
