import { AppStartListening } from '.';
import { addFeedCallListenerMiddleware } from '../../apps/feedlotManager/modules/feed/feedCall/listenerMiddlewares/feedCallListenerMiddleware';
import { addManageCommoditiesListeners_EXAMPLE } from '../../modules/commodities/manageCommodities/listenerMiddlewares/manageCommoditiesListenerMiddleware';
import { isLocalEnvironment } from '../../utils/isLocalEnv';

export const initializeListenerMiddlewares = (
    startAppListening: AppStartListening,
) => {
    addManageCommoditiesListeners_EXAMPLE(startAppListening);
    addFeedCallListenerMiddleware(startAppListening);

    if (isLocalEnvironment && process.env.NODE_ENV !== 'test') {
        console.log('Listener Middlewares Initialized');
    }
};
