import { createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../Store';
import { initializeListenerMiddlewares } from './initializeListenerMiddlewares';

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening.withTypes<
    RootState,
    AppDispatch
>();

export type AppStartListening = typeof startAppListening;

initializeListenerMiddlewares(startAppListening);
