import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { currentDate_deprecated } from '../../../../helpers';
import {
    FreightType,
    IScaleTicketInitialState,
} from '../interfaces/ScaleTicketInterface';
import {
    EditScaleTicketConstants,
    ScaleTicketConstants,
    scaleTicketLabelConstants,
} from './ScaleTicketConstants';

const {
    isCA9800On_ContractsMVP,
    isCA11479On_UpdateScaleTicketUrl,
    isCA12552On_ScaleTicketTrucingCompanyNotRequired,
} = feedlotManagerFeatures;

export const SCALE_TICKET_INITIAL_STATE: IScaleTicketInitialState = {
    selectedScaleTicketTab: ScaleTicketConstants.tabs.inboundScaleTicket,
    loading: true,
    canCallApi: true,
    scaleTicketsFilter: {
        include: isCA11479On_UpdateScaleTicketUrl
            ? 'Contract,Vendor'
            : isCA9800On_ContractsMVP
              ? 'Contract,Contract.Vendor,Contract.Commodity,Vendor'
              : 'Contract,Contract.Vendor,Contract.Commodity',
        limit: 20,
        offset: 0,
        filter: 'ScaleType eq 1',
        sort: '^TDate',
    },
    scaleTickets: [],
    contractModalDetails: {
        contractName: '',
        vendorId: 0,
        payee: '',
        contractCreationDate: currentDate_deprecated('YYYY-MM-DD'),
        commodityId: 0,
        price: '',
        totalAmount: '',
        dmPercentage: '',
        rate: '',
        comment: '',
        contractType: '',
    },
    contractModalDetailsError: {
        contractName: false,
        vendorId: false,
        payee: false,
        contractCreationDate: false,
        commodityId: false,
        price: false,
        totalAmount: false,
        dmPercentage: false,
        freightType: false,
        rate: false,
        notPriced: false,
    },
    vendorModalDetails: {
        name: '',
        contactName: '',
        contactNumber: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
    },
    vendorModalDetailsError: {
        name: false,
        contactName: false,
        contactNumber: false,
        address: false,
        city: false,
        state: false,
        zipCode: false,
    },
    truckingCompanyModalDetails: {
        name: '',
        email: '',
        altEmail: '',
        contactNumber: '',
        altContactNumber: '',
        contactName: '',
        altContactName: '',
    },
    truckingCompanyModalDetailsError: {
        name: false,
        email: false,
        contactNumber: false,
        contactName: false,
    },
    editScaleTicketDetails: {
        tDate: currentDate_deprecated('YYYY-MM-DD'),
        commodity: 0,
        contractId: 0,
        price: '',
        grossWeight: '',
        tare: '',
        truckingCompanyId: isCA12552On_ScaleTicketTrucingCompanyNotRequired
            ? undefined
            : 0,
        freightRate: isCA9800On_ContractsMVP ? '' : undefined,
        driverName: '',
        notes: '',
        scaleType: 0,
        vendor: 0,
        dmPercentage: isCA9800On_ContractsMVP ? '' : undefined,
        scaleTicketNumber: '',
        netWeight: '',
        netWeightInTons: '',
        billOfLading: '',
    },
    createScaleTicketDetails: {
        scaleTicketBasicDetails: {
            tDate: currentDate_deprecated('YYYY-MM-DD'),
            commodity: 0,
            contractId: 0,
            vendor: 0,
            dmPercentage: isCA9800On_ContractsMVP ? '' : undefined,
            price: '',
            scaleType: 0,
        },
        scaleTicketSegments: [
            {
                grossWeight: '',
                tare: '',
                truckingCompanyId:
                    isCA12552On_ScaleTicketTrucingCompanyNotRequired
                        ? undefined
                        : 0,
                freightRate: isCA9800On_ContractsMVP ? '' : undefined,
                driverName: '',
                notes: '',
                scaleTicketNumber: '',
                netWeight: '',
                netWeightInTons: '',
                billOfLading: '',
                dmPercentage: '',
            },
        ],
    },
    editScaleTicketDetailsError: {
        tDate: false,
        commodity: false,
        contractId: false,
        grossWeight: false,
        tare: false,
        truckingCompanyId: false,
        freightRate: false,
        scaleType: false,
        vendor: false,
        dmPercentage: false,
        scaleTicketNumber: false,
        billOfLading: false,
    },
    createScaleTicketDetailsError: {
        tDate: false,
        commodity: false,
        contractId: false,
        scaleType: false,
        vendor: false,
        scaleTicketSegmentError: [
            {
                grossWeight: false,
                tare: false,
                truckingCompanyId: false,
                freightRate: false,
                scaleTicketNumber: false,
                billOfLading: false,
            },
        ],
    },
    vendors: [
        {
            name: scaleTicketLabelConstants.createVendor,
            id: scaleTicketLabelConstants.createVendor,
        },
    ],
    commodities: [{ name: scaleTicketLabelConstants.selectCommodity, id: 0 }],
    contracts: isCA9800On_ContractsMVP
        ? [
              {
                  name: scaleTicketLabelConstants.selectContract,
                  id: 0,
                  price: 0,
              },
              {
                  name: scaleTicketLabelConstants.createContract,
                  id: scaleTicketLabelConstants.createContract,
                  price: 0,
              },
              {
                  name: scaleTicketLabelConstants.noContract,
                  id: EditScaleTicketConstants.contractType.noContract,
                  price: 0,
              },
              {
                  name: scaleTicketLabelConstants.assignContractLater,
                  id: EditScaleTicketConstants.contractType.assignContractLater,
                  price: 0,
              },
          ]
        : [
              {
                  name: scaleTicketLabelConstants.selectContract,
                  id: 0,
                  price: 0,
              },
              {
                  name: scaleTicketLabelConstants.createContract,
                  id: scaleTicketLabelConstants.createContract,
                  price: 0,
              },
          ],
    completeContracts: [],
    truckingCompanies: [
        {
            name: scaleTicketLabelConstants.selectCompany,
            id: 0,
        },
        {
            name: scaleTicketLabelConstants.createTruckingCompany,
            id: scaleTicketLabelConstants.createTruckingCompany,
        },
    ],
    freights: Object.values(FreightType),
    scaleTicketsExcelData: [],
    submitPending: false,
};
