import { rootApi as api } from '../rootApi';
export const addTagTypes = ['Accounts'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: build => ({
            postApiTmAccountsSignup: build.mutation<
                PostApiTmAccountsSignupApiResponse,
                PostApiTmAccountsSignupApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/signup`,
                    method: 'POST',
                    body: queryArg.tenantManagementModelsUserManagementRequestAccountCreationModel,
                    params: {
                        tenantId: queryArg.tenantId,
                    },
                }),
                invalidatesTags: ['Accounts'],
            }),
            postApiTmAccounts: build.mutation<
                PostApiTmAccountsApiResponse,
                PostApiTmAccountsApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts`,
                    method: 'POST',
                    body: queryArg.tenantManagementModelsUserManagementRequestAccountCreationModel,
                    params: {
                        tenantId: queryArg.tenantId,
                    },
                }),
                invalidatesTags: ['Accounts'],
            }),
            getApiTmAccounts: build.query<
                GetApiTmAccountsApiResponse,
                GetApiTmAccountsApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts`,
                    params: {
                        accountIds: queryArg.accountIds,
                        include: queryArg.include,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        includeDisabled: queryArg.includeDisabled,
                    },
                }),
                providesTags: ['Accounts'],
            }),
            getApiTmAccountsByName: build.query<
                GetApiTmAccountsByNameApiResponse,
                GetApiTmAccountsByNameApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.name}`,
                    params: {
                        include: queryArg.include,
                    },
                }),
                providesTags: ['Accounts'],
            }),
            getApiTmAccountsById: build.query<
                GetApiTmAccountsByIdApiResponse,
                GetApiTmAccountsByIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}`,
                    params: {
                        include: queryArg.include,
                    },
                }),
                providesTags: ['Accounts'],
            }),
            putApiTmAccountsById: build.mutation<
                PutApiTmAccountsByIdApiResponse,
                PutApiTmAccountsByIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.tenantManagementModelsUserManagementAccountModel,
                }),
                invalidatesTags: ['Accounts'],
            }),
            getApiTmAccountsOdata: build.query<
                GetApiTmAccountsOdataApiResponse,
                GetApiTmAccountsOdataApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/odata`,
                    params: {
                        queryOptions: queryArg.queryOptions,
                        include: queryArg.include,
                        includeDisabled: queryArg.includeDisabled,
                    },
                }),
                providesTags: ['Accounts'],
            }),
            getApiTmAccountsByUserName: build.query<
                GetApiTmAccountsByUserNameApiResponse,
                GetApiTmAccountsByUserNameApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.userName}`,
                    params: {
                        include: queryArg.include,
                    },
                }),
                providesTags: ['Accounts'],
            }),
            postApiTmAccountsByIdUsers: build.mutation<
                PostApiTmAccountsByIdUsersApiResponse,
                PostApiTmAccountsByIdUsersApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}/users`,
                    method: 'POST',
                    body: queryArg.tenantManagementModelsUserManagementUserModel,
                }),
                invalidatesTags: ['Accounts'],
            }),
            postApiTmAccountsByIdUsersAndUsername: build.mutation<
                PostApiTmAccountsByIdUsersAndUsernameApiResponse,
                PostApiTmAccountsByIdUsersAndUsernameApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}/users/${queryArg.username}`,
                    method: 'POST',
                    body: queryArg.body,
                    params: {
                        template: queryArg.template,
                    },
                }),
                invalidatesTags: ['Accounts'],
            }),
            postApiTmAccountsByIdUsersAndUserId: build.mutation<
                PostApiTmAccountsByIdUsersAndUserIdApiResponse,
                PostApiTmAccountsByIdUsersAndUserIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}/users/${queryArg.userId}`,
                    method: 'POST',
                    body: queryArg.body,
                    params: {
                        template: queryArg.template,
                    },
                }),
                invalidatesTags: ['Accounts'],
            }),
            putApiTmAccountsByIdUsersAndUserId: build.mutation<
                PutApiTmAccountsByIdUsersAndUserIdApiResponse,
                PutApiTmAccountsByIdUsersAndUserIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}/users/${queryArg.userId}`,
                    method: 'PUT',
                    body: queryArg.body,
                }),
                invalidatesTags: ['Accounts'],
            }),
            deleteApiTmAccountsByIdUsersAndUserId: build.mutation<
                DeleteApiTmAccountsByIdUsersAndUserIdApiResponse,
                DeleteApiTmAccountsByIdUsersAndUserIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}/users/${queryArg.userId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Accounts'],
            }),
            putApiTmAccountsByIdUsersAndUserIdInvite: build.mutation<
                PutApiTmAccountsByIdUsersAndUserIdInviteApiResponse,
                PutApiTmAccountsByIdUsersAndUserIdInviteApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.id}/users/${queryArg.userId}/invite`,
                    method: 'PUT',
                }),
                invalidatesTags: ['Accounts'],
            }),
            deleteApiTmAccountsByAccountId: build.mutation<
                DeleteApiTmAccountsByAccountIdApiResponse,
                DeleteApiTmAccountsByAccountIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.accountId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Accounts'],
            }),
            postApiTmAccountsProfileImage: build.mutation<
                PostApiTmAccountsProfileImageApiResponse,
                PostApiTmAccountsProfileImageApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/profile/image`,
                    method: 'POST',
                    body: queryArg.body,
                    params: {
                        id: queryArg.id,
                    },
                }),
                invalidatesTags: ['Accounts'],
            }),
            getApiTmAccountsProfileImage: build.query<
                GetApiTmAccountsProfileImageApiResponse,
                GetApiTmAccountsProfileImageApiArg
            >({
                query: () => ({ url: `/api/tm/accounts/profile/image` }),
                providesTags: ['Accounts'],
            }),
            deleteApiTmAccountsProfileImage: build.mutation<
                DeleteApiTmAccountsProfileImageApiResponse,
                DeleteApiTmAccountsProfileImageApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/profile/image`,
                    method: 'DELETE',
                    params: {
                        id: queryArg.id,
                    },
                }),
                invalidatesTags: ['Accounts'],
            }),
            getApiTmAccountsByAccountIdProfileImage: build.query<
                GetApiTmAccountsByAccountIdProfileImageApiResponse,
                GetApiTmAccountsByAccountIdProfileImageApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts/${queryArg.accountId}/profile/image`,
                }),
                providesTags: ['Accounts'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as baseAccountsApi };
export type PostApiTmAccountsSignupApiResponse =
    /** status 200 Success */ TenantManagementModelsUserManagementResponseAccountCreationResponseModel;
export type PostApiTmAccountsSignupApiArg = {
    tenantId?: string;
    tenantManagementModelsUserManagementRequestAccountCreationModel: TenantManagementModelsUserManagementRequestAccountCreationModel;
};
export type PostApiTmAccountsApiResponse =
    /** status 200 Success */ TenantManagementModelsUserManagementResponseAccountCreationResponseModel;
export type PostApiTmAccountsApiArg = {
    tenantId?: string;
    tenantManagementModelsUserManagementRequestAccountCreationModel: TenantManagementModelsUserManagementRequestAccountCreationModel;
};
export type GetApiTmAccountsApiResponse = unknown;
export type GetApiTmAccountsApiArg = {
    accountIds?: number[];
    include?: string;
    filter?: string;
    sort?: string[];
    limit?: number;
    offset?: number;
    includeDisabled?: boolean;
};
export type GetApiTmAccountsByNameApiResponse =
    /** status 200 Success */ TenantManagementModelsUserManagementAccountBaseModel;
export type GetApiTmAccountsByNameApiArg = {
    name: string;
    include?: string;
};
export type GetApiTmAccountsByIdApiResponse =
    /** status 200 Success */ TenantManagementModelsUserManagementAccountBaseModel;
export type GetApiTmAccountsByIdApiArg = {
    id: number;
    include?: string;
};
export type PutApiTmAccountsByIdApiResponse = unknown;
export type PutApiTmAccountsByIdApiArg = {
    id: number;
    tenantManagementModelsUserManagementAccountModel: TenantManagementModelsUserManagementAccountModel;
};
export type GetApiTmAccountsOdataApiResponse =
    /** status 200 Success */ TenantManagementModelsUserManagementAccountBaseModel[];
export type GetApiTmAccountsOdataApiArg = {
    queryOptions?: MicrosoftAspNetCoreODataQueryODataQueryOptions601TenantManagementDataEntitiesAccount;
    include?: string;
    includeDisabled?: boolean;
};
export type GetApiTmAccountsByUserNameApiResponse =
    /** status 200 Success */ TenantManagementDataEntitiesAccountUserRead;
export type GetApiTmAccountsByUserNameApiArg = {
    userName: string;
    include?: string;
};
export type PostApiTmAccountsByIdUsersApiResponse =
    /** status 200 Success */ number;
export type PostApiTmAccountsByIdUsersApiArg = {
    id: number;
    tenantManagementModelsUserManagementUserModel: TenantManagementModelsUserManagementUserModel;
};
export type PostApiTmAccountsByIdUsersAndUsernameApiResponse = unknown;
export type PostApiTmAccountsByIdUsersAndUsernameApiArg = {
    id: number;
    username: string;
    template?: TenantManagementCommonEmailTemplates;
    body: TenantManagementCommonRoles[];
};
export type PostApiTmAccountsByIdUsersAndUserIdApiResponse = unknown;
export type PostApiTmAccountsByIdUsersAndUserIdApiArg = {
    id: number;
    userId: number;
    template?: TenantManagementCommonEmailTemplates;
    body: TenantManagementCommonRoles[];
};
export type PutApiTmAccountsByIdUsersAndUserIdApiResponse = unknown;
export type PutApiTmAccountsByIdUsersAndUserIdApiArg = {
    id: number;
    userId: number;
    body: TenantManagementCommonRoles[];
};
export type DeleteApiTmAccountsByIdUsersAndUserIdApiResponse = unknown;
export type DeleteApiTmAccountsByIdUsersAndUserIdApiArg = {
    id: number;
    userId: number;
};
export type PutApiTmAccountsByIdUsersAndUserIdInviteApiResponse = unknown;
export type PutApiTmAccountsByIdUsersAndUserIdInviteApiArg = {
    id: number;
    userId: number;
};
export type DeleteApiTmAccountsByAccountIdApiResponse = unknown;
export type DeleteApiTmAccountsByAccountIdApiArg = {
    accountId: number;
};
export type PostApiTmAccountsProfileImageApiResponse =
    /** status 200 Success */ number;
export type PostApiTmAccountsProfileImageApiArg = {
    id?: number;
    body: {
        image: Blob;
    };
};
export type GetApiTmAccountsProfileImageApiResponse = unknown;
export type GetApiTmAccountsProfileImageApiArg = void;
export type DeleteApiTmAccountsProfileImageApiResponse =
    /** status 200 Success */ number;
export type DeleteApiTmAccountsProfileImageApiArg = {
    id?: number;
};
export type GetApiTmAccountsByAccountIdProfileImageApiResponse = unknown;
export type GetApiTmAccountsByAccountIdProfileImageApiArg = {
    accountId: number;
};
export type TenantManagementModelsUserManagementResponseAccountCreationResponseModel =
    {
        accountId?: number;
        userId?: number;
    };
export type TenantManagementCommonEnumsAddressType =
    | 'Home'
    | 'Business'
    | 'Billing'
    | 'Shipping';
export type TenantManagementModelsUserManagementAddressModel = {
    addressId?: number;
    city?: string | null;
    country?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    state?: string | null;
    streetAddress?: string | null;
    streetAddress2?: string | null;
    type?: TenantManagementCommonEnumsAddressType;
};
export type TenantManagementModelsUserManagementAccountLeafModel = {
    accountId?: number;
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    name?: string | null;
    timezoneInfo?: string | null;
};
export type TenantManagementModelsUserManagementRoleModel = {
    name?: string | null;
    roleId?: number;
};
export type TenantManagementModelsUserManagementScopeModel = {
    name?: string | null;
    scopeId?: number;
};
export type TenantManagementModelsUserManagementUserLeafModel = {
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    profileImageId?: string | null;
    roles?: TenantManagementModelsUserManagementRoleModel[] | null;
    scopes?: TenantManagementModelsUserManagementScopeModel[] | null;
    timezoneInfo?: string | null;
    userId?: number;
    username?: string | null;
    userpass?: string | null;
    verified?: boolean | null;
};
export type TenantManagementModelsUserManagementUserLeafModelRead = {
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    profileImageId?: string | null;
    roles?: TenantManagementModelsUserManagementRoleModel[] | null;
    scopes?: TenantManagementModelsUserManagementScopeModel[] | null;
    timezoneInfo?: string | null;
    userId?: number;
    username?: string | null;
    userpass?: string | null;
    verified?: boolean | null;
};
export type TenantManagementModelsUserManagementAccountUserModel = {
    account?: TenantManagementModelsUserManagementAccountLeafModel;
    accountId?: number;
    roles?: TenantManagementModelsUserManagementRoleModel[] | null;
    user?: TenantManagementModelsUserManagementUserLeafModel;
    userId?: number;
};
export type TenantManagementModelsUserManagementAccountUserModelRead = {
    account?: TenantManagementModelsUserManagementAccountLeafModel;
    accountId?: number;
    roles?: TenantManagementModelsUserManagementRoleModel[] | null;
    user?: TenantManagementModelsUserManagementUserLeafModelRead;
    userId?: number;
};
export type TenantManagementModelsUserManagementUserModel = {
    accounts?: TenantManagementModelsUserManagementAccountUserModel[] | null;
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    profileImageId?: string | null;
    roles?: TenantManagementModelsUserManagementRoleModel[] | null;
    scopes?: TenantManagementModelsUserManagementScopeModel[] | null;
    timezoneInfo?: string | null;
    userId?: number;
    username?: string | null;
    userpass?: string | null;
    verified?: boolean | null;
};
export type TenantManagementModelsUserManagementUserModelRead = {
    accounts?:
        | TenantManagementModelsUserManagementAccountUserModelRead[]
        | null;
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    profileImageId?: string | null;
    roles?: TenantManagementModelsUserManagementRoleModel[] | null;
    scopes?: TenantManagementModelsUserManagementScopeModel[] | null;
    timezoneInfo?: string | null;
    userId?: number;
    username?: string | null;
    userpass?: string | null;
    verified?: boolean | null;
};
export type TenantManagementModelsUserManagementRequestAccountCreationModel = {
    accountId?: number;
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    name?: string | null;
    owner?: TenantManagementModelsUserManagementUserModel;
    timezoneInfo?: string | null;
    users?: TenantManagementModelsUserManagementAccountUserModel[] | null;
};
export type TenantManagementModelsUserManagementRequestAccountCreationModelRead =
    {
        accountId?: number;
        addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
        attributes?: {
            [key: string]: any | null;
        } | null;
        contactEmail?: string | null;
        contactFirstName?: string | null;
        contactLastName?: string | null;
        contactPhone?: string | null;
        enabled?: boolean | null;
        name?: string | null;
        owner?: TenantManagementModelsUserManagementUserModelRead;
        timezoneInfo?: string | null;
        users?:
            | TenantManagementModelsUserManagementAccountUserModelRead[]
            | null;
    };
export type TenantManagementModelsUserManagementAccountBaseModel = {
    accountId?: number;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    enabled?: boolean | null;
    name?: string | null;
    timezoneInfo?: string | null;
};
export type TenantManagementModelsUserManagementAccountModel = {
    accountId?: number;
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    name?: string | null;
    timezoneInfo?: string | null;
    users?: TenantManagementModelsUserManagementAccountUserModel[] | null;
};
export type TenantManagementModelsUserManagementAccountModelRead = {
    accountId?: number;
    addresses?: TenantManagementModelsUserManagementAddressModel[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    enabled?: boolean | null;
    name?: string | null;
    timezoneInfo?: string | null;
    users?: TenantManagementModelsUserManagementAccountUserModelRead[] | null;
};
export type MicrosoftODataUriParserAggregationTransformationNodeKind =
    | 'Aggregate'
    | 'GroupBy'
    | 'Filter'
    | 'Compute'
    | 'Expand';
export type MicrosoftODataUriParserAggregationTransformationNode = {
    kind?: MicrosoftODataUriParserAggregationTransformationNodeKind;
};
export type MicrosoftODataUriParserAggregationApplyClause = {
    transformations?:
        | MicrosoftODataUriParserAggregationTransformationNode[]
        | null;
};
export type MicrosoftAspNetCoreODataQueryDefaultQueryConfigurations = {
    enableCount?: boolean;
    enableExpand?: boolean;
    enableFilter?: boolean;
    enableOrderBy?: boolean;
    enableSelect?: boolean;
    enableSkipToken?: boolean;
    maxTop?: number | null;
};
export type SystemReflectionMethodAttributes =
    | 'ReuseSlot'
    | 'Private'
    | 'FamANDAssem'
    | 'Assembly'
    | 'Family'
    | 'FamORAssem'
    | 'Public'
    | 'MemberAccessMask'
    | 'UnmanagedExport'
    | 'Static'
    | 'Final'
    | 'Virtual'
    | 'HideBySig'
    | 'NewSlot'
    | 'CheckAccessOnOverride'
    | 'Abstract'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'PinvokeImpl'
    | 'HasSecurity'
    | 'RequireSecObject'
    | 'ReservedMask';
export type SystemReflectionCallingConventions =
    | 'Standard'
    | 'VarArgs'
    | 'Any'
    | 'HasThis'
    | 'ExplicitThis';
export type SystemReflectionMemberTypes =
    | 'Constructor'
    | 'Event'
    | 'Field'
    | 'Method'
    | 'Property'
    | 'TypeInfo'
    | 'Custom'
    | 'NestedType'
    | 'All';
export type SystemIntPtr = object;
export type SystemRuntimeMethodHandle = {
    value?: SystemIntPtr;
};
export type SystemReflectionMethodImplAttributes =
    | 'IL'
    | 'Native'
    | 'OPTIL'
    | 'Runtime'
    | 'Unmanaged'
    | 'NoInlining'
    | 'ForwardRef'
    | 'Synchronized'
    | 'NoOptimization'
    | 'PreserveSig'
    | 'AggressiveInlining'
    | 'AggressiveOptimization'
    | 'InternalCall'
    | 'MaxMethodImplVal';
export type SystemModuleHandle = {};
export type SystemModuleHandleRead = {
    mdStreamVersion?: number;
};
export type SystemReflectionModule = {
    assembly?: SystemReflectionAssembly;
    moduleHandle?: SystemModuleHandle;
};
export type SystemReflectionConstructorInfo = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionConstructorInfoRead = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeData[] | null;
    declaringType?: SystemType;
    isAbstract?: boolean;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isConstructedGenericMethod?: boolean;
    isConstructor?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isFinal?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    isHideBySig?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionCustomAttributeData = {
    attributeType?: SystemType;
    constructor?: SystemReflectionConstructorInfo;
};
export type SystemReflectionCustomAttributeTypedArgument = {
    argumentType?: SystemType;
    value?: any | null;
};
export type SystemReflectionCustomAttributeTypedArgumentRead = {
    argumentType?: SystemType;
    value?: any | null;
};
export type SystemReflectionMemberInfo = {
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionMemberInfoRead = {
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    isCollectible?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionCustomAttributeNamedArgument = {
    memberInfo?: SystemReflectionMemberInfo;
    typedValue?: SystemReflectionCustomAttributeTypedArgument;
};
export type SystemReflectionCustomAttributeNamedArgumentRead = {
    isField?: boolean;
    memberInfo?: SystemReflectionMemberInfoRead;
    memberName?: string | null;
    typedValue?: SystemReflectionCustomAttributeTypedArgumentRead;
};
export type SystemReflectionCustomAttributeDataRead = {
    attributeType?: SystemType;
    constructor?: SystemReflectionConstructorInfoRead;
    constructorArguments?:
        | SystemReflectionCustomAttributeTypedArgumentRead[]
        | null;
    namedArguments?: SystemReflectionCustomAttributeNamedArgumentRead[] | null;
};
export type SystemReflectionModuleRead = {
    assembly?: SystemReflectionAssembly;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    fullyQualifiedName?: string | null;
    mdStreamVersion?: number;
    metadataToken?: number;
    moduleHandle?: SystemModuleHandleRead;
    moduleVersionId?: string;
    name?: string | null;
    scopeName?: string | null;
};
export type SystemReflectionParameterAttributes =
    | 'None'
    | 'In'
    | 'Out'
    | 'Lcid'
    | 'Retval'
    | 'Optional'
    | 'HasDefault'
    | 'HasFieldMarshal'
    | 'Reserved3'
    | 'Reserved4'
    | 'ReservedMask';
export type SystemReflectionParameterInfo = {
    attributes?: SystemReflectionParameterAttributes;
    member?: SystemReflectionMemberInfo;
    parameterType?: SystemType;
};
export type SystemReflectionParameterInfoRead = {
    attributes?: SystemReflectionParameterAttributes;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    defaultValue?: any | null;
    hasDefaultValue?: boolean;
    isIn?: boolean;
    isLcid?: boolean;
    isOptional?: boolean;
    isOut?: boolean;
    isRetval?: boolean;
    member?: SystemReflectionMemberInfoRead;
    metadataToken?: number;
    name?: string | null;
    parameterType?: SystemType;
    position?: number;
    rawDefaultValue?: any | null;
};
export type SystemReflectionICustomAttributeProvider = object;
export type SystemReflectionMethodInfo = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
    returnParameter?: SystemReflectionParameterInfo;
    returnType?: SystemType;
    returnTypeCustomAttributes?: SystemReflectionICustomAttributeProvider;
};
export type SystemReflectionMethodInfoRead = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    isAbstract?: boolean;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isConstructedGenericMethod?: boolean;
    isConstructor?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isFinal?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    isHideBySig?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
    returnParameter?: SystemReflectionParameterInfoRead;
    returnType?: SystemType;
    returnTypeCustomAttributes?: SystemReflectionICustomAttributeProvider;
};
export type SystemSecuritySecurityRuleSet = 'None' | 'Level1' | 'Level2';
export type SystemReflectionAssembly = {
    entryPoint?: SystemReflectionMethodInfo;
    manifestModule?: SystemReflectionModule;
    securityRuleSet?: SystemSecuritySecurityRuleSet;
};
export type SystemReflectionTypeAttributes =
    | 'NotPublic'
    | 'Public'
    | 'NestedPublic'
    | 'NestedPrivate'
    | 'NestedFamily'
    | 'NestedAssembly'
    | 'NestedFamANDAssem'
    | 'NestedFamORAssem'
    | 'SequentialLayout'
    | 'ExplicitLayout'
    | 'LayoutMask'
    | 'Interface'
    | 'Abstract'
    | 'Sealed'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'Import'
    | 'Serializable'
    | 'WindowsRuntime'
    | 'UnicodeClass'
    | 'AutoClass'
    | 'CustomFormatClass'
    | 'HasSecurity'
    | 'ReservedMask'
    | 'BeforeFieldInit'
    | 'CustomFormatMask';
export type SystemReflectionMethodBase = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionMethodBaseRead = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    isAbstract?: boolean;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isConstructedGenericMethod?: boolean;
    isConstructor?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isFinal?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    isHideBySig?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionGenericParameterAttributes =
    | 'None'
    | 'Covariant'
    | 'Contravariant'
    | 'VarianceMask'
    | 'ReferenceTypeConstraint'
    | 'NotNullableValueTypeConstraint'
    | 'DefaultConstructorConstraint'
    | 'SpecialConstraintMask';
export type SystemRuntimeInteropServicesLayoutKind =
    | 'Sequential'
    | 'Explicit'
    | 'Auto';
export type SystemRuntimeInteropServicesStructLayoutAttribute = {
    value?: SystemRuntimeInteropServicesLayoutKind;
};
export type SystemRuntimeInteropServicesStructLayoutAttributeRead = {
    typeId?: any | null;
    value?: SystemRuntimeInteropServicesLayoutKind;
};
export type SystemRuntimeTypeHandle = {
    value?: SystemIntPtr;
};
export type SystemReflectionTypeInfo = {
    assembly?: SystemReflectionAssembly;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemType;
    declaringMethod?: SystemReflectionMethodBase;
    declaringType?: SystemType;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttribute;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfo;
    underlyingSystemType?: SystemType;
};
export type SystemReflectionEventAttributes =
    | 'None'
    | 'SpecialName'
    | 'RTSpecialName';
export type SystemReflectionEventInfo = {
    addMethod?: SystemReflectionMethodInfo;
    attributes?: SystemReflectionEventAttributes;
    declaringType?: SystemType;
    eventHandlerType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    raiseMethod?: SystemReflectionMethodInfo;
    reflectedType?: SystemType;
    removeMethod?: SystemReflectionMethodInfo;
};
export type SystemReflectionEventInfoRead = {
    addMethod?: SystemReflectionMethodInfoRead;
    attributes?: SystemReflectionEventAttributes;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    eventHandlerType?: SystemType;
    isCollectible?: boolean;
    isMulticast?: boolean;
    isSpecialName?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    raiseMethod?: SystemReflectionMethodInfoRead;
    reflectedType?: SystemType;
    removeMethod?: SystemReflectionMethodInfoRead;
};
export type SystemReflectionFieldAttributes =
    | 'PrivateScope'
    | 'Private'
    | 'FamANDAssem'
    | 'Assembly'
    | 'Family'
    | 'FamORAssem'
    | 'Public'
    | 'FieldAccessMask'
    | 'Static'
    | 'InitOnly'
    | 'Literal'
    | 'NotSerialized'
    | 'HasFieldRVA'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'HasFieldMarshal'
    | 'PinvokeImpl'
    | 'HasDefault'
    | 'ReservedMask';
export type SystemRuntimeFieldHandle = {
    value?: SystemIntPtr;
};
export type SystemReflectionFieldInfo = {
    attributes?: SystemReflectionFieldAttributes;
    declaringType?: SystemType;
    fieldHandle?: SystemRuntimeFieldHandle;
    fieldType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionFieldInfoRead = {
    attributes?: SystemReflectionFieldAttributes;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    fieldHandle?: SystemRuntimeFieldHandle;
    fieldType?: SystemType;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isInitOnly?: boolean;
    isLiteral?: boolean;
    isNotSerialized?: boolean;
    isPinvokeImpl?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionPropertyAttributes =
    | 'None'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'HasDefault'
    | 'Reserved2'
    | 'Reserved3'
    | 'Reserved4'
    | 'ReservedMask';
export type SystemReflectionPropertyInfo = {
    attributes?: SystemReflectionPropertyAttributes;
    declaringType?: SystemType;
    getMethod?: SystemReflectionMethodInfo;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    propertyType?: SystemType;
    reflectedType?: SystemType;
    setMethod?: SystemReflectionMethodInfo;
};
export type SystemReflectionPropertyInfoRead = {
    attributes?: SystemReflectionPropertyAttributes;
    canRead?: boolean;
    canWrite?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    getMethod?: SystemReflectionMethodInfoRead;
    isCollectible?: boolean;
    isSpecialName?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    propertyType?: SystemType;
    reflectedType?: SystemType;
    setMethod?: SystemReflectionMethodInfoRead;
};
export type SystemReflectionTypeInfoRead = {
    assembly?: SystemReflectionAssemblyRead;
    assemblyQualifiedName?: string | null;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemType;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaredConstructors?: SystemReflectionConstructorInfoRead[] | null;
    declaredEvents?: SystemReflectionEventInfoRead[] | null;
    declaredFields?: SystemReflectionFieldInfoRead[] | null;
    declaredMembers?: SystemReflectionMemberInfoRead[] | null;
    declaredMethods?: SystemReflectionMethodInfoRead[] | null;
    declaredNestedTypes?: SystemReflectionTypeInfoRead[] | null;
    declaredProperties?: SystemReflectionPropertyInfoRead[] | null;
    declaringMethod?: SystemReflectionMethodBaseRead;
    declaringType?: SystemType;
    fullName?: string | null;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    genericParameterPosition?: number;
    genericTypeArguments?: SystemType[] | null;
    genericTypeParameters?: SystemType[] | null;
    guid?: string;
    hasElementType?: boolean;
    implementedInterfaces?: SystemType[] | null;
    isAbstract?: boolean;
    isAnsiClass?: boolean;
    isArray?: boolean;
    isAutoClass?: boolean;
    isAutoLayout?: boolean;
    isByRef?: boolean;
    isByRefLike?: boolean;
    isClass?: boolean;
    isCollectible?: boolean;
    isCOMObject?: boolean;
    isConstructedGenericType?: boolean;
    isContextful?: boolean;
    isEnum?: boolean;
    isExplicitLayout?: boolean;
    isGenericMethodParameter?: boolean;
    isGenericParameter?: boolean;
    isGenericType?: boolean;
    isGenericTypeDefinition?: boolean;
    isGenericTypeParameter?: boolean;
    isImport?: boolean;
    isInterface?: boolean;
    isLayoutSequential?: boolean;
    isMarshalByRef?: boolean;
    isNested?: boolean;
    isNestedAssembly?: boolean;
    isNestedFamANDAssem?: boolean;
    isNestedFamily?: boolean;
    isNestedFamORAssem?: boolean;
    isNestedPrivate?: boolean;
    isNestedPublic?: boolean;
    isNotPublic?: boolean;
    isPointer?: boolean;
    isPrimitive?: boolean;
    isPublic?: boolean;
    isSealed?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSerializable?: boolean;
    isSignatureType?: boolean;
    isSpecialName?: boolean;
    isSZArray?: boolean;
    isTypeDefinition?: boolean;
    isUnicodeClass?: boolean;
    isValueType?: boolean;
    isVariableBoundArray?: boolean;
    isVisible?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    namespace?: string | null;
    reflectedType?: SystemType;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttributeRead;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfoRead;
    underlyingSystemType?: SystemType;
};
export type SystemReflectionAssemblyRead = {
    codeBase?: string | null;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    definedTypes?: SystemReflectionTypeInfoRead[] | null;
    entryPoint?: SystemReflectionMethodInfoRead;
    escapedCodeBase?: string | null;
    exportedTypes?: SystemType[] | null;
    fullName?: string | null;
    globalAssemblyCache?: boolean;
    hostContext?: number;
    imageRuntimeVersion?: string | null;
    isCollectible?: boolean;
    isDynamic?: boolean;
    isFullyTrusted?: boolean;
    location?: string | null;
    manifestModule?: SystemReflectionModuleRead;
    modules?: SystemReflectionModuleRead[] | null;
    reflectionOnly?: boolean;
    securityRuleSet?: SystemSecuritySecurityRuleSet;
};
export type SystemType = {
    assembly?: SystemReflectionAssembly;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemType;
    declaringMethod?: SystemReflectionMethodBase;
    declaringType?: SystemType;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttribute;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfo;
    underlyingSystemType?: SystemType;
};
export type SystemTypeRead = {
    assembly?: SystemReflectionAssemblyRead;
    assemblyQualifiedName?: string | null;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemTypeRead;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringMethod?: SystemReflectionMethodBaseRead;
    declaringType?: SystemTypeRead;
    fullName?: string | null;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    genericParameterPosition?: number;
    genericTypeArguments?: SystemTypeRead[] | null;
    guid?: string;
    hasElementType?: boolean;
    isAbstract?: boolean;
    isAnsiClass?: boolean;
    isArray?: boolean;
    isAutoClass?: boolean;
    isAutoLayout?: boolean;
    isByRef?: boolean;
    isByRefLike?: boolean;
    isClass?: boolean;
    isCollectible?: boolean;
    isCOMObject?: boolean;
    isConstructedGenericType?: boolean;
    isContextful?: boolean;
    isEnum?: boolean;
    isExplicitLayout?: boolean;
    isGenericMethodParameter?: boolean;
    isGenericParameter?: boolean;
    isGenericType?: boolean;
    isGenericTypeDefinition?: boolean;
    isGenericTypeParameter?: boolean;
    isImport?: boolean;
    isInterface?: boolean;
    isLayoutSequential?: boolean;
    isMarshalByRef?: boolean;
    isNested?: boolean;
    isNestedAssembly?: boolean;
    isNestedFamANDAssem?: boolean;
    isNestedFamily?: boolean;
    isNestedFamORAssem?: boolean;
    isNestedPrivate?: boolean;
    isNestedPublic?: boolean;
    isNotPublic?: boolean;
    isPointer?: boolean;
    isPrimitive?: boolean;
    isPublic?: boolean;
    isSealed?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSerializable?: boolean;
    isSignatureType?: boolean;
    isSpecialName?: boolean;
    isSZArray?: boolean;
    isTypeDefinition?: boolean;
    isUnicodeClass?: boolean;
    isValueType?: boolean;
    isVariableBoundArray?: boolean;
    isVisible?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    namespace?: string | null;
    reflectedType?: SystemTypeRead;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttributeRead;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfoRead;
    underlyingSystemType?: SystemTypeRead;
};
export type MicrosoftODataEdmEdmTypeKind =
    | 'None'
    | 'Primitive'
    | 'Entity'
    | 'Complex'
    | 'Collection'
    | 'EntityReference'
    | 'Enum'
    | 'TypeDefinition'
    | 'Untyped'
    | 'Path';
export type MicrosoftODataEdmIEdmType = {
    typeKind?: MicrosoftODataEdmEdmTypeKind;
};
export type MicrosoftODataEdmVocabulariesIEdmDirectValueAnnotationsManager =
    object;
export type MicrosoftODataEdmEdmSchemaElementKind =
    | 'None'
    | 'TypeDefinition'
    | 'Term'
    | 'Action'
    | 'EntityContainer'
    | 'Function';
export type MicrosoftODataEdmIEdmEntityContainer = {
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmEdmContainerElementKind =
    | 'None'
    | 'EntitySet'
    | 'ActionImport'
    | 'FunctionImport'
    | 'Singleton';
export type MicrosoftODataEdmIEdmEntityContainerElement = {
    container?: MicrosoftODataEdmIEdmEntityContainer;
    containerElementKind?: MicrosoftODataEdmEdmContainerElementKind;
};
export type MicrosoftODataEdmIEdmEntityContainerElementRead = {
    container?: MicrosoftODataEdmIEdmEntityContainerRead;
    containerElementKind?: MicrosoftODataEdmEdmContainerElementKind;
    name?: string | null;
};
export type MicrosoftODataEdmIEdmEntityContainerRead = {
    elements?: MicrosoftODataEdmIEdmEntityContainerElementRead[] | null;
    name?: string | null;
    namespace?: string | null;
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmIEdmModel = {
    directValueAnnotationsManager?: MicrosoftODataEdmVocabulariesIEdmDirectValueAnnotationsManager;
    entityContainer?: MicrosoftODataEdmIEdmEntityContainer;
};
export type MicrosoftODataEdmIEdmSchemaElement = {
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmIEdmSchemaElementRead = {
    name?: string | null;
    namespace?: string | null;
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotatable = object;
export type MicrosoftODataEdmIEdmTypeReference = {
    definition?: MicrosoftODataEdmIEdmType;
};
export type MicrosoftODataEdmIEdmTypeReferenceRead = {
    definition?: MicrosoftODataEdmIEdmType;
    isNullable?: boolean;
};
export type MicrosoftODataEdmVocabulariesIEdmTerm = {
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmVocabulariesIEdmTermRead = {
    appliesTo?: string | null;
    defaultValue?: string | null;
    name?: string | null;
    namespace?: string | null;
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmEdmExpressionKind =
    | 'None'
    | 'BinaryConstant'
    | 'BooleanConstant'
    | 'DateTimeOffsetConstant'
    | 'DecimalConstant'
    | 'FloatingConstant'
    | 'GuidConstant'
    | 'IntegerConstant'
    | 'StringConstant'
    | 'DurationConstant'
    | 'Null'
    | 'Record'
    | 'Collection'
    | 'Path'
    | 'If'
    | 'Cast'
    | 'IsType'
    | 'FunctionApplication'
    | 'LabeledExpressionReference'
    | 'Labeled'
    | 'PropertyPath'
    | 'NavigationPropertyPath'
    | 'DateConstant'
    | 'TimeOfDayConstant'
    | 'EnumMember'
    | 'AnnotationPath';
export type MicrosoftODataEdmIEdmExpression = {
    expressionKind?: MicrosoftODataEdmEdmExpressionKind;
};
export type MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotation = {
    target?: MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotatable;
    term?: MicrosoftODataEdmVocabulariesIEdmTerm;
    value?: MicrosoftODataEdmIEdmExpression;
};
export type MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotationRead = {
    qualifier?: string | null;
    target?: MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotatable;
    term?: MicrosoftODataEdmVocabulariesIEdmTermRead;
    value?: MicrosoftODataEdmIEdmExpression;
};
export type MicrosoftODataEdmIEdmModelRead = {
    declaredNamespaces?: string[] | null;
    directValueAnnotationsManager?: MicrosoftODataEdmVocabulariesIEdmDirectValueAnnotationsManager;
    entityContainer?: MicrosoftODataEdmIEdmEntityContainerRead;
    referencedModels?: MicrosoftODataEdmIEdmModelRead[] | null;
    schemaElements?: MicrosoftODataEdmIEdmSchemaElementRead[] | null;
    vocabularyAnnotations?:
        | MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotationRead[]
        | null;
};
export type MicrosoftODataEdmIEdmPathExpression = {
    expressionKind?: MicrosoftODataEdmEdmExpressionKind;
};
export type MicrosoftODataEdmIEdmPathExpressionRead = {
    expressionKind?: MicrosoftODataEdmEdmExpressionKind;
    path?: string | null;
    pathSegments?: string[] | null;
};
export type MicrosoftODataEdmIEdmNavigationSource = {
    path?: MicrosoftODataEdmIEdmPathExpression;
    type?: MicrosoftODataEdmIEdmType;
};
export type MicrosoftODataEdmIEdmStructuredType = {
    baseType?: MicrosoftODataEdmIEdmStructuredType;
    typeKind?: MicrosoftODataEdmEdmTypeKind;
};
export type MicrosoftODataEdmEdmPropertyKind =
    | 'None'
    | 'Structural'
    | 'Navigation';
export type MicrosoftODataEdmIEdmProperty = {
    declaringType?: MicrosoftODataEdmIEdmStructuredType;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmIEdmPropertyRead = {
    declaringType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    name?: string | null;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmIEdmStructuredTypeRead = {
    baseType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    declaredProperties?: MicrosoftODataEdmIEdmPropertyRead[] | null;
    isAbstract?: boolean;
    isOpen?: boolean;
    typeKind?: MicrosoftODataEdmEdmTypeKind;
};
export type MicrosoftODataEdmEdmOnDeleteAction = 'None' | 'Cascade';
export type MicrosoftODataEdmIEdmReferentialConstraint = {};
export type MicrosoftODataEdmIEdmStructuralProperty = {
    declaringType?: MicrosoftODataEdmIEdmStructuredType;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmIEdmStructuralPropertyRead = {
    declaringType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    defaultValueString?: string | null;
    name?: string | null;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmEdmReferentialConstraintPropertyPair = {
    dependentProperty?: MicrosoftODataEdmIEdmStructuralProperty;
    principalProperty?: MicrosoftODataEdmIEdmStructuralProperty;
};
export type MicrosoftODataEdmEdmReferentialConstraintPropertyPairRead = {
    dependentProperty?: MicrosoftODataEdmIEdmStructuralPropertyRead;
    principalProperty?: MicrosoftODataEdmIEdmStructuralPropertyRead;
};
export type MicrosoftODataEdmIEdmReferentialConstraintRead = {
    propertyPairs?:
        | MicrosoftODataEdmEdmReferentialConstraintPropertyPairRead[]
        | null;
};
export type MicrosoftODataEdmIEdmNavigationProperty = {
    declaringType?: MicrosoftODataEdmIEdmStructuredType;
    onDelete?: MicrosoftODataEdmEdmOnDeleteAction;
    partner?: MicrosoftODataEdmIEdmNavigationProperty;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    referentialConstraint?: MicrosoftODataEdmIEdmReferentialConstraint;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmIEdmNavigationPropertyRead = {
    containsTarget?: boolean;
    declaringType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    name?: string | null;
    onDelete?: MicrosoftODataEdmEdmOnDeleteAction;
    partner?: MicrosoftODataEdmIEdmNavigationPropertyRead;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    referentialConstraint?: MicrosoftODataEdmIEdmReferentialConstraintRead;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmIEdmNavigationPropertyBinding = {
    navigationProperty?: MicrosoftODataEdmIEdmNavigationProperty;
    path?: MicrosoftODataEdmIEdmPathExpression;
    target?: MicrosoftODataEdmIEdmNavigationSource;
};
export type MicrosoftODataEdmIEdmNavigationPropertyBindingRead = {
    navigationProperty?: MicrosoftODataEdmIEdmNavigationPropertyRead;
    path?: MicrosoftODataEdmIEdmPathExpressionRead;
    target?: MicrosoftODataEdmIEdmNavigationSourceRead;
};
export type MicrosoftODataEdmIEdmNavigationSourceRead = {
    name?: string | null;
    navigationPropertyBindings?:
        | MicrosoftODataEdmIEdmNavigationPropertyBindingRead[]
        | null;
    path?: MicrosoftODataEdmIEdmPathExpressionRead;
    type?: MicrosoftODataEdmIEdmType;
};
export type MicrosoftODataUriParserODataPathSegment = {
    edmType?: MicrosoftODataEdmIEdmType;
    identifier?: string | null;
};
export type SystemIServiceProvider = object;
export type MicrosoftAspNetCoreODataQueryODataQueryContext = {
    defaultQueryConfigurations?: MicrosoftAspNetCoreODataQueryDefaultQueryConfigurations;
    elementClrType?: SystemType;
    elementType?: MicrosoftODataEdmIEdmType;
    model?: MicrosoftODataEdmIEdmModel;
    navigationSource?: MicrosoftODataEdmIEdmNavigationSource;
    path?: MicrosoftODataUriParserODataPathSegment[] | null;
    requestContainer?: SystemIServiceProvider;
};
export type MicrosoftAspNetCoreODataQueryODataQueryContextRead = {
    defaultQueryConfigurations?: MicrosoftAspNetCoreODataQueryDefaultQueryConfigurations;
    elementClrType?: SystemTypeRead;
    elementType?: MicrosoftODataEdmIEdmType;
    model?: MicrosoftODataEdmIEdmModelRead;
    navigationSource?: MicrosoftODataEdmIEdmNavigationSourceRead;
    path?: MicrosoftODataUriParserODataPathSegment[] | null;
    requestContainer?: SystemIServiceProvider;
};
export type MicrosoftAspNetCoreODataQueryApplyQueryOption = {
    applyClause?: MicrosoftODataUriParserAggregationApplyClause;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    resultClrType?: SystemType;
};
export type MicrosoftAspNetCoreODataQueryApplyQueryOptionRead = {
    applyClause?: MicrosoftODataUriParserAggregationApplyClause;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    resultClrType?: SystemTypeRead;
};
export type MicrosoftODataUriParserQueryNodeKind =
    | 'None'
    | 'Constant'
    | 'Convert'
    | 'NonResourceRangeVariableReference'
    | 'BinaryOperator'
    | 'UnaryOperator'
    | 'SingleValuePropertyAccess'
    | 'CollectionPropertyAccess'
    | 'SingleValueFunctionCall'
    | 'Any'
    | 'CollectionNavigationNode'
    | 'SingleNavigationNode'
    | 'SingleValueOpenPropertyAccess'
    | 'SingleResourceCast'
    | 'All'
    | 'CollectionResourceCast'
    | 'ResourceRangeVariableReference'
    | 'SingleResourceFunctionCall'
    | 'CollectionFunctionCall'
    | 'CollectionResourceFunctionCall'
    | 'NamedFunctionParameter'
    | 'ParameterAlias'
    | 'EntitySet'
    | 'KeyLookup'
    | 'SearchTerm'
    | 'CollectionOpenPropertyAccess'
    | 'CollectionComplexNode'
    | 'SingleComplexNode'
    | 'Count'
    | 'SingleValueCast'
    | 'CollectionPropertyNode'
    | 'AggregatedCollectionPropertyNode'
    | 'In'
    | 'CollectionConstant';
export type MicrosoftODataUriParserSingleValueNode = {
    kind?: MicrosoftODataUriParserQueryNodeKind;
    typeReference?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataUriParserSingleValueNodeRead = {
    kind?: MicrosoftODataUriParserQueryNodeKind;
    typeReference?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataUriParserComputeExpression = {
    alias?: string | null;
    expression?: MicrosoftODataUriParserSingleValueNode;
    typeReference?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataUriParserComputeExpressionRead = {
    alias?: string | null;
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
    typeReference?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataUriParserComputeClause = {
    computedItems?: MicrosoftODataUriParserComputeExpression[] | null;
};
export type MicrosoftODataUriParserComputeClauseRead = {
    computedItems?: MicrosoftODataUriParserComputeExpressionRead[] | null;
};
export type MicrosoftAspNetCoreODataQueryValidatorIComputeQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQueryComputeQueryOption = {
    computeClause?: MicrosoftODataUriParserComputeClause;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    resultClrType?: SystemType;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIComputeQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryComputeQueryOptionRead = {
    computeClause?: MicrosoftODataUriParserComputeClauseRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    resultClrType?: SystemTypeRead;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIComputeQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryValidatorICountQueryValidator = object;
export type MicrosoftAspNetCoreODataQueryCountQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorICountQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryCountQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorICountQueryValidator;
    value?: boolean;
};
export type MicrosoftODataUriParserRangeVariable = {
    typeReference?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataUriParserRangeVariableRead = {
    kind?: number;
    name?: string | null;
    typeReference?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataUriParserFilterClause = {
    expression?: MicrosoftODataUriParserSingleValueNode;
    itemType?: MicrosoftODataEdmIEdmTypeReference;
    rangeVariable?: MicrosoftODataUriParserRangeVariable;
};
export type MicrosoftODataUriParserFilterClauseRead = {
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
    itemType?: MicrosoftODataEdmIEdmTypeReferenceRead;
    rangeVariable?: MicrosoftODataUriParserRangeVariableRead;
};
export type MicrosoftAspNetCoreODataQueryValidatorIFilterQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQueryFilterQueryOption = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    filterClause?: MicrosoftODataUriParserFilterClause;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIFilterQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryFilterQueryOptionRead = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    filterClause?: MicrosoftODataUriParserFilterClauseRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIFilterQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryETag601TenantManagementDataEntitiesAccount =
    {
        entityType?: SystemType;
        isAny?: boolean;
        isIfNoneMatch?: boolean;
        isWellFormed?: boolean;
    };
export type MicrosoftAspNetCoreODataQueryETag601TenantManagementDataEntitiesAccountRead =
    {
        entityType?: SystemTypeRead;
        isAny?: boolean;
        isIfNoneMatch?: boolean;
        isWellFormed?: boolean;
    };
export type MicrosoftODataUriParserOrderByDirection =
    | 'Ascending'
    | 'Descending';
export type MicrosoftODataUriParserOrderByClause = {
    direction?: MicrosoftODataUriParserOrderByDirection;
    expression?: MicrosoftODataUriParserSingleValueNode;
    itemType?: MicrosoftODataEdmIEdmTypeReference;
    rangeVariable?: MicrosoftODataUriParserRangeVariable;
    thenBy?: MicrosoftODataUriParserOrderByClause;
};
export type MicrosoftODataUriParserOrderByClauseRead = {
    direction?: MicrosoftODataUriParserOrderByDirection;
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
    itemType?: MicrosoftODataEdmIEdmTypeReferenceRead;
    rangeVariable?: MicrosoftODataUriParserRangeVariableRead;
    thenBy?: MicrosoftODataUriParserOrderByClauseRead;
};
export type MicrosoftAspNetCoreODataQueryValidatorIOrderByQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQueryOrderByQueryOption = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    orderByClause?: MicrosoftODataUriParserOrderByClause;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIOrderByQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryOrderByNode = {
    direction?: MicrosoftODataUriParserOrderByDirection;
};
export type MicrosoftAspNetCoreODataQueryOrderByQueryOptionRead = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    orderByClause?: MicrosoftODataUriParserOrderByClauseRead;
    orderByNodes?: MicrosoftAspNetCoreODataQueryOrderByNode[] | null;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIOrderByQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryODataRawQueryOptions = {};
export type MicrosoftAspNetCoreODataQueryODataRawQueryOptionsRead = {
    apply?: string | null;
    compute?: string | null;
    count?: string | null;
    deltaToken?: string | null;
    expand?: string | null;
    filter?: string | null;
    format?: string | null;
    orderBy?: string | null;
    search?: string | null;
    select?: string | null;
    skip?: string | null;
    skipToken?: string | null;
    top?: string | null;
};
export type SystemIoStream = {
    position?: number;
    readTimeout?: number;
    writeTimeout?: number;
};
export type SystemIoStreamRead = {
    canRead?: boolean;
    canSeek?: boolean;
    canTimeout?: boolean;
    canWrite?: boolean;
    length?: number;
    position?: number;
    readTimeout?: number;
    writeTimeout?: number;
};
export type SystemIoPipelinesPipeReader = object;
export type SystemCollectionsGenericKeyValuePair602SystemStringSystemString = {
    key?: string | null;
    value?: string | null;
};
export type SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues =
    {
        key?: string | null;
        value?: string[];
    };
export type MicrosoftAspNetCoreHttpHostString = {
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpHostStringRead = {
    hasValue?: boolean;
    host?: string | null;
    port?: number | null;
    value?: string | null;
};
export type SystemSecurityCryptographyOid = {
    friendlyName?: string | null;
    value?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX500DistinguishedName = {
    oid?: SystemSecurityCryptographyOid;
    rawData?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX500DistinguishedNameRead =
    {
        name?: string | null;
        oid?: SystemSecurityCryptographyOid;
        rawData?: string | null;
    };
export type SystemSecurityCryptographyAsymmetricAlgorithm = {
    keySize?: number;
};
export type SystemSecurityCryptographyKeySizes = {
    maxSize?: number;
    minSize?: number;
    skipSize?: number;
};
export type SystemSecurityCryptographyAsymmetricAlgorithmRead = {
    keyExchangeAlgorithm?: string | null;
    keySize?: number;
    legalKeySizes?: SystemSecurityCryptographyKeySizes[] | null;
    signatureAlgorithm?: string | null;
};
export type SystemSecurityCryptographyAsnEncodedData = {
    oid?: SystemSecurityCryptographyOid;
    rawData?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesPublicKey = {
    encodedKeyValue?: SystemSecurityCryptographyAsnEncodedData;
    encodedParameters?: SystemSecurityCryptographyAsnEncodedData;
    key?: SystemSecurityCryptographyAsymmetricAlgorithm;
    oid?: SystemSecurityCryptographyOid;
};
export type SystemSecurityCryptographyX509CertificatesPublicKeyRead = {
    encodedKeyValue?: SystemSecurityCryptographyAsnEncodedData;
    encodedParameters?: SystemSecurityCryptographyAsnEncodedData;
    key?: SystemSecurityCryptographyAsymmetricAlgorithmRead;
    oid?: SystemSecurityCryptographyOid;
};
export type SystemSecurityCryptographyX509CertificatesX509Certificate2 = {
    archived?: boolean;
    friendlyName?: string | null;
    handle?: SystemIntPtr;
    issuerName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedName;
    privateKey?: SystemSecurityCryptographyAsymmetricAlgorithm;
    publicKey?: SystemSecurityCryptographyX509CertificatesPublicKey;
    signatureAlgorithm?: SystemSecurityCryptographyOid;
    subjectName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedName;
};
export type SystemSecurityCryptographyX509CertificatesX509Extension = {
    critical?: boolean;
    oid?: SystemSecurityCryptographyOid;
    rawData?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX509Certificate2Read = {
    archived?: boolean;
    extensions?:
        | SystemSecurityCryptographyX509CertificatesX509Extension[]
        | null;
    friendlyName?: string | null;
    handle?: SystemIntPtr;
    hasPrivateKey?: boolean;
    issuer?: string | null;
    issuerName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedNameRead;
    notAfter?: string;
    notBefore?: string;
    privateKey?: SystemSecurityCryptographyAsymmetricAlgorithmRead;
    publicKey?: SystemSecurityCryptographyX509CertificatesPublicKeyRead;
    rawData?: string | null;
    serialNumber?: string | null;
    signatureAlgorithm?: SystemSecurityCryptographyOid;
    subject?: string | null;
    subjectName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedNameRead;
    thumbprint?: string | null;
    version?: number;
};
export type SystemNetSocketsAddressFamily =
    | 'Unspecified'
    | 'Unix'
    | 'InterNetwork'
    | 'ImpLink'
    | 'Pup'
    | 'Chaos'
    | 'NS'
    | 'Osi'
    | 'Ecma'
    | 'DataKit'
    | 'Ccitt'
    | 'Sna'
    | 'DecNet'
    | 'DataLink'
    | 'Lat'
    | 'HyperChannel'
    | 'AppleTalk'
    | 'NetBios'
    | 'VoiceView'
    | 'FireFox'
    | 'Banyan'
    | 'Atm'
    | 'InterNetworkV6'
    | 'Cluster'
    | 'Ieee12844'
    | 'Irda'
    | 'NetworkDesigners'
    | 'Max'
    | 'Packet'
    | 'ControllerAreaNetwork'
    | 'Unknown';
export type SystemNetIpAddress = {
    address?: number;
    addressFamily?: SystemNetSocketsAddressFamily;
    scopeId?: number;
};
export type SystemNetIpAddressRead = {
    address?: number;
    addressFamily?: SystemNetSocketsAddressFamily;
    isIPv4MappedToIPv6?: boolean;
    isIPv6LinkLocal?: boolean;
    isIPv6Multicast?: boolean;
    isIPv6SiteLocal?: boolean;
    isIPv6Teredo?: boolean;
    isIPv6UniqueLocal?: boolean;
    scopeId?: number;
};
export type MicrosoftAspNetCoreHttpConnectionInfo = {
    clientCertificate?: SystemSecurityCryptographyX509CertificatesX509Certificate2;
    id?: string | null;
    localIpAddress?: SystemNetIpAddress;
    localPort?: number;
    remoteIpAddress?: SystemNetIpAddress;
    remotePort?: number;
};
export type MicrosoftAspNetCoreHttpConnectionInfoRead = {
    clientCertificate?: SystemSecurityCryptographyX509CertificatesX509Certificate2Read;
    id?: string | null;
    localIpAddress?: SystemNetIpAddressRead;
    localPort?: number;
    remoteIpAddress?: SystemNetIpAddressRead;
    remotePort?: number;
};
export type MicrosoftWin32SafeHandlesSafeWaitHandle = {};
export type MicrosoftWin32SafeHandlesSafeWaitHandleRead = {
    isClosed?: boolean;
    isInvalid?: boolean;
};
export type SystemThreadingWaitHandle = {
    handle?: SystemIntPtr;
    safeWaitHandle?: MicrosoftWin32SafeHandlesSafeWaitHandle;
};
export type SystemThreadingWaitHandleRead = {
    handle?: SystemIntPtr;
    safeWaitHandle?: MicrosoftWin32SafeHandlesSafeWaitHandleRead;
};
export type SystemThreadingCancellationToken = {
    waitHandle?: SystemThreadingWaitHandle;
};
export type SystemThreadingCancellationTokenRead = {
    canBeCanceled?: boolean;
    isCancellationRequested?: boolean;
    waitHandle?: SystemThreadingWaitHandleRead;
};
export type SystemIoPipelinesPipeWriter = {};
export type SystemIoPipelinesPipeWriterRead = {
    canGetUnflushedBytes?: boolean;
    unflushedBytes?: number;
};
export type MicrosoftAspNetCoreHttpIResponseCookies = object;
export type MicrosoftAspNetCoreHttpHttpResponse = {
    body?: SystemIoStream;
    bodyWriter?: SystemIoPipelinesPipeWriter;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?: MicrosoftAspNetCoreHttpIResponseCookies;
    httpContext?: MicrosoftAspNetCoreHttpHttpContext;
    statusCode?: number;
};
export type MicrosoftAspNetCoreHttpHttpResponseRead = {
    body?: SystemIoStreamRead;
    bodyWriter?: SystemIoPipelinesPipeWriterRead;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?: MicrosoftAspNetCoreHttpIResponseCookies;
    hasStarted?: boolean;
    headers?: {
        [key: string]: string[];
    } | null;
    httpContext?: MicrosoftAspNetCoreHttpHttpContext;
    statusCode?: number;
};
export type MicrosoftAspNetCoreHttpISession = {};
export type MicrosoftAspNetCoreHttpISessionRead = {
    id?: string | null;
    isAvailable?: boolean;
    keys?: string[] | null;
};
export type SystemSecurityPrincipalIIdentity = {};
export type SystemSecurityPrincipalIIdentityRead = {
    authenticationType?: string | null;
    isAuthenticated?: boolean;
    name?: string | null;
};
export type SystemSecurityClaimsClaimsPrincipal = {
    identity?: SystemSecurityPrincipalIIdentity;
};
export type SystemSecurityClaimsClaimsIdentity = {
    actor?: SystemSecurityClaimsClaimsIdentity;
    bootstrapContext?: any | null;
    label?: string | null;
};
export type SystemSecurityClaimsClaimsIdentityRead = {
    actor?: SystemSecurityClaimsClaimsIdentityRead;
    authenticationType?: string | null;
    bootstrapContext?: any | null;
    claims?: SystemSecurityClaimsClaim[] | null;
    isAuthenticated?: boolean;
    label?: string | null;
    name?: string | null;
    nameClaimType?: string | null;
    roleClaimType?: string | null;
};
export type SystemSecurityClaimsClaim = {
    subject?: SystemSecurityClaimsClaimsIdentity;
};
export type SystemSecurityClaimsClaimRead = {
    issuer?: string | null;
    originalIssuer?: string | null;
    properties?: {
        [key: string]: string;
    } | null;
    subject?: SystemSecurityClaimsClaimsIdentityRead;
    type?: string | null;
    value?: string | null;
    valueType?: string | null;
};
export type SystemSecurityClaimsClaimsPrincipalRead = {
    claims?: SystemSecurityClaimsClaimRead[] | null;
    identities?: SystemSecurityClaimsClaimsIdentityRead[] | null;
    identity?: SystemSecurityPrincipalIIdentityRead;
};
export type MicrosoftAspNetCoreHttpWebSocketManager = {};
export type MicrosoftAspNetCoreHttpWebSocketManagerRead = {
    isWebSocketRequest?: boolean;
    webSocketRequestedProtocols?: string[] | null;
};
export type MicrosoftAspNetCoreHttpHttpContext = {
    connection?: MicrosoftAspNetCoreHttpConnectionInfo;
    items?: {
        [key: string]: any;
    } | null;
    request?: MicrosoftAspNetCoreHttpHttpRequest;
    requestAborted?: SystemThreadingCancellationToken;
    requestServices?: SystemIServiceProvider;
    response?: MicrosoftAspNetCoreHttpHttpResponse;
    session?: MicrosoftAspNetCoreHttpISession;
    traceIdentifier?: string | null;
    user?: SystemSecurityClaimsClaimsPrincipal;
    webSockets?: MicrosoftAspNetCoreHttpWebSocketManager;
};
export type SystemCollectionsGenericKeyValuePair602SystemTypeSystemObject = {
    key?: SystemType;
    value?: any | null;
};
export type SystemCollectionsGenericKeyValuePair602SystemTypeSystemObjectRead =
    {
        key?: SystemTypeRead;
        value?: any | null;
    };
export type MicrosoftAspNetCoreHttpHttpContextRead = {
    connection?: MicrosoftAspNetCoreHttpConnectionInfoRead;
    features?:
        | SystemCollectionsGenericKeyValuePair602SystemTypeSystemObjectRead[]
        | null;
    items?: {
        [key: string]: any;
    } | null;
    request?: MicrosoftAspNetCoreHttpHttpRequest;
    requestAborted?: SystemThreadingCancellationTokenRead;
    requestServices?: SystemIServiceProvider;
    response?: MicrosoftAspNetCoreHttpHttpResponseRead;
    session?: MicrosoftAspNetCoreHttpISessionRead;
    traceIdentifier?: string | null;
    user?: SystemSecurityClaimsClaimsPrincipalRead;
    webSockets?: MicrosoftAspNetCoreHttpWebSocketManagerRead;
};
export type MicrosoftAspNetCoreHttpPathString = {
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpPathStringRead = {
    hasValue?: boolean;
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpQueryString = {
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpQueryStringRead = {
    hasValue?: boolean;
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpHttpRequest = {
    body?: SystemIoStream;
    bodyReader?: SystemIoPipelinesPipeReader;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?:
        | SystemCollectionsGenericKeyValuePair602SystemStringSystemString[]
        | null;
    form?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    host?: MicrosoftAspNetCoreHttpHostString;
    httpContext?: MicrosoftAspNetCoreHttpHttpContext;
    isHttps?: boolean;
    method?: string | null;
    path?: MicrosoftAspNetCoreHttpPathString;
    pathBase?: MicrosoftAspNetCoreHttpPathString;
    protocol?: string | null;
    query?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    queryString?: MicrosoftAspNetCoreHttpQueryString;
    routeValues?: {
        [key: string]: any;
    } | null;
    scheme?: string | null;
};
export type MicrosoftAspNetCoreHttpHttpRequestRead = {
    body?: SystemIoStreamRead;
    bodyReader?: SystemIoPipelinesPipeReader;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?:
        | SystemCollectionsGenericKeyValuePair602SystemStringSystemString[]
        | null;
    form?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    hasFormContentType?: boolean;
    headers?: {
        [key: string]: string[];
    } | null;
    host?: MicrosoftAspNetCoreHttpHostStringRead;
    httpContext?: MicrosoftAspNetCoreHttpHttpContextRead;
    isHttps?: boolean;
    method?: string | null;
    path?: MicrosoftAspNetCoreHttpPathStringRead;
    pathBase?: MicrosoftAspNetCoreHttpPathStringRead;
    protocol?: string | null;
    query?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    queryString?: MicrosoftAspNetCoreHttpQueryStringRead;
    routeValues?: {
        [key: string]: any;
    } | null;
    scheme?: string | null;
};
export type MicrosoftODataUriParserSearchClause = {
    expression?: MicrosoftODataUriParserSingleValueNode;
};
export type MicrosoftODataUriParserSearchClauseRead = {
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
};
export type MicrosoftAspNetCoreODataQuerySearchQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    resultClrType?: SystemType;
    searchClause?: MicrosoftODataUriParserSearchClause;
};
export type MicrosoftAspNetCoreODataQuerySearchQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    resultClrType?: SystemTypeRead;
    searchClause?: MicrosoftODataUriParserSearchClauseRead;
};
export type MicrosoftODataUriParserSelectItem = object;
export type MicrosoftODataUriParserSelectExpandClause = {
    allSelected?: boolean;
    selectedItems?: MicrosoftODataUriParserSelectItem[] | null;
};
export type MicrosoftAspNetCoreODataQueryValidatorISelectExpandQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQuerySelectExpandQueryOption = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    levelsMaxLiteralExpansionDepth?: number;
    selectExpandClause?: MicrosoftODataUriParserSelectExpandClause;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISelectExpandQueryValidator;
};
export type MicrosoftAspNetCoreODataQuerySelectExpandQueryOptionRead = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    levelsMaxLiteralExpansionDepth?: number;
    rawExpand?: string | null;
    rawSelect?: string | null;
    selectExpandClause?: MicrosoftODataUriParserSelectExpandClause;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISelectExpandQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryValidatorISkipQueryValidator = object;
export type MicrosoftAspNetCoreODataQuerySkipQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipQueryValidator;
};
export type MicrosoftAspNetCoreODataQuerySkipQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipQueryValidator;
    value?: number;
};
export type MicrosoftAspNetCoreODataQuerySkipTokenHandler = object;
export type MicrosoftAspNetCoreODataQueryValidatorISkipTokenQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQuerySkipTokenQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    handler?: MicrosoftAspNetCoreODataQuerySkipTokenHandler;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipTokenQueryValidator;
};
export type MicrosoftAspNetCoreODataQuerySkipTokenQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    handler?: MicrosoftAspNetCoreODataQuerySkipTokenHandler;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipTokenQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryValidatorITopQueryValidator = object;
export type MicrosoftAspNetCoreODataQueryTopQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorITopQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryTopQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorITopQueryValidator;
    value?: number;
};
export type MicrosoftAspNetCoreODataQueryValidatorIoDataQueryValidator = object;
export type MicrosoftAspNetCoreODataQueryODataQueryOptions601TenantManagementDataEntitiesAccount =
    {
        apply?: MicrosoftAspNetCoreODataQueryApplyQueryOption;
        compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
        context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
        count?: MicrosoftAspNetCoreODataQueryCountQueryOption;
        filter?: MicrosoftAspNetCoreODataQueryFilterQueryOption;
        ifMatch?: MicrosoftAspNetCoreODataQueryETag601TenantManagementDataEntitiesAccount;
        ifNoneMatch?: MicrosoftAspNetCoreODataQueryETag601TenantManagementDataEntitiesAccount;
        orderBy?: MicrosoftAspNetCoreODataQueryOrderByQueryOption;
        rawValues?: MicrosoftAspNetCoreODataQueryODataRawQueryOptions;
        request?: MicrosoftAspNetCoreHttpHttpRequest;
        search?: MicrosoftAspNetCoreODataQuerySearchQueryOption;
        selectExpand?: MicrosoftAspNetCoreODataQuerySelectExpandQueryOption;
        skip?: MicrosoftAspNetCoreODataQuerySkipQueryOption;
        skipToken?: MicrosoftAspNetCoreODataQuerySkipTokenQueryOption;
        top?: MicrosoftAspNetCoreODataQueryTopQueryOption;
        validator?: MicrosoftAspNetCoreODataQueryValidatorIoDataQueryValidator;
    };
export type MicrosoftAspNetCoreODataQueryODataQueryOptions601TenantManagementDataEntitiesAccountRead =
    {
        apply?: MicrosoftAspNetCoreODataQueryApplyQueryOptionRead;
        compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
        context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
        count?: MicrosoftAspNetCoreODataQueryCountQueryOptionRead;
        filter?: MicrosoftAspNetCoreODataQueryFilterQueryOptionRead;
        ifMatch?: MicrosoftAspNetCoreODataQueryETag601TenantManagementDataEntitiesAccountRead;
        ifNoneMatch?: MicrosoftAspNetCoreODataQueryETag601TenantManagementDataEntitiesAccountRead;
        orderBy?: MicrosoftAspNetCoreODataQueryOrderByQueryOptionRead;
        rawValues?: MicrosoftAspNetCoreODataQueryODataRawQueryOptionsRead;
        request?: MicrosoftAspNetCoreHttpHttpRequestRead;
        search?: MicrosoftAspNetCoreODataQuerySearchQueryOptionRead;
        selectExpand?: MicrosoftAspNetCoreODataQuerySelectExpandQueryOptionRead;
        skip?: MicrosoftAspNetCoreODataQuerySkipQueryOptionRead;
        skipToken?: MicrosoftAspNetCoreODataQuerySkipTokenQueryOptionRead;
        top?: MicrosoftAspNetCoreODataQueryTopQueryOptionRead;
        validator?: MicrosoftAspNetCoreODataQueryValidatorIoDataQueryValidator;
    };
export type TenantManagementCommonEnumsMfaType = 'Email' | 'SMS';
export type TenantManagementCommonRoles =
    | 'None'
    | 'AppAdmin'
    | 'BackgroundService'
    | 'AnyUserRole'
    | 'AnyManageRole'
    | 'Owner'
    | 'Admin'
    | 'SuperAdmin'
    | 'Manager'
    | 'User'
    | 'AnyAccountRole'
    | 'AnyAccountManageRole'
    | 'AccountOwner'
    | 'AccountAdmin'
    | 'AccountManager'
    | 'AccountUser'
    | 'FeedlotManager'
    | 'OfficeManager'
    | 'InvoiceManager'
    | 'HealthManager'
    | 'Cowboy'
    | 'Operator'
    | 'FeedTruckDriver'
    | 'Finance'
    | 'WarehousingApp'
    | 'Buyer'
    | 'TruckingCompany'
    | 'AccountStakeholder';
export type TenantManagementDataEntitiesRole = {
    accountUsers?: TenantManagementDataEntitiesAccountUser[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: TenantManagementCommonRoles;
    roleId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesRoleRead = {
    accountUsers?: TenantManagementDataEntitiesAccountUser[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: TenantManagementCommonRoles;
    roleId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesScope = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    scopeId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesScopeRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    scopeId?: number;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementModelsUserManagementSubscription = {
    customerId?: string | null;
    productFamily?: string | null;
    subscriptionId?: string | null;
    subscriptionName?: string | null;
    subscriptionPlan?: string | null;
};
export type TenantManagementModelsUserManagementTenantContext = {
    subscriptions?: TenantManagementModelsUserManagementSubscription[] | null;
};
export type TenantManagementDataEntitiesTenant = {
    accounts?: TenantManagementDataEntitiesAccount[] | null;
    contactEmail?: string | null;
    contactName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profile?: TenantManagementModelsUserManagementTenantContext;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesTenantRead = {
    accounts?: TenantManagementDataEntitiesAccount[] | null;
    contactEmail?: string | null;
    contactName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profile?: TenantManagementModelsUserManagementTenantContext;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesUser = {
    accounts?: TenantManagementDataEntitiesAccountUser[] | null;
    addresses?: TenantManagementDataEntitiesAddress[] | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    mfaType?: TenantManagementCommonEnumsMfaType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    profileImageId?: string | null;
    refreshToken?: string | null;
    refreshTokenExpiry?: string | null;
    roles?: TenantManagementDataEntitiesRole[] | null;
    salt?: string | null;
    scopes?: TenantManagementDataEntitiesScope[] | null;
    tenant?: TenantManagementDataEntitiesTenant;
    tenantId?: string;
    timezoneInfo?: string | null;
    trackedDisabledAccounts?: TenantManagementDataEntitiesAccountUser[] | null;
    transientAuthExpiry?: string | null;
    transientAuthToken?: string | null;
    transientContext?: string | null;
    userhash?: string | null;
    userId?: number;
    username?: string | null;
    verified?: boolean | null;
};
export type TenantManagementDataEntitiesUserRead = {
    accounts?: TenantManagementDataEntitiesAccountUser[] | null;
    addresses?: TenantManagementDataEntitiesAddress[] | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    latestLogin?: string | null;
    mfaType?: TenantManagementCommonEnumsMfaType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    profileImageId?: string | null;
    refreshToken?: string | null;
    refreshTokenExpiry?: string | null;
    roles?: TenantManagementDataEntitiesRoleRead[] | null;
    salt?: string | null;
    scopes?: TenantManagementDataEntitiesScopeRead[] | null;
    tenant?: TenantManagementDataEntitiesTenantRead;
    tenantId?: string;
    timezoneInfo?: string | null;
    trackedDisabledAccounts?: TenantManagementDataEntitiesAccountUser[] | null;
    transientAuthExpiry?: string | null;
    transientAuthToken?: string | null;
    transientContext?: string | null;
    userhash?: string | null;
    userId?: number;
    username?: string | null;
    verified?: boolean | null;
};
export type TenantManagementDataEntitiesAddress = {
    accounts?: TenantManagementDataEntitiesAccount[] | null;
    addressId?: number;
    city?: string | null;
    country?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    state?: string | null;
    streetAddress?: string | null;
    streetAddress2?: string | null;
    type?: TenantManagementCommonEnumsAddressType;
    users?: TenantManagementDataEntitiesUser[] | null;
};
export type TenantManagementDataEntitiesAddressRead = {
    accounts?: TenantManagementDataEntitiesAccount[] | null;
    addressId?: number;
    city?: string | null;
    country?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    state?: string | null;
    streetAddress?: string | null;
    streetAddress2?: string | null;
    type?: TenantManagementCommonEnumsAddressType;
    users?: TenantManagementDataEntitiesUserRead[] | null;
};
export type TenantManagementDataEntitiesAccount = {
    accountId?: number;
    addresses?: TenantManagementDataEntitiesAddress[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profileImageId?: string | null;
    tenant?: TenantManagementDataEntitiesTenant;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesAccountUser[] | null;
};
export type TenantManagementDataEntitiesAccountRead = {
    accountId?: number;
    addresses?: TenantManagementDataEntitiesAddressRead[] | null;
    attributes?: {
        [key: string]: any | null;
    } | null;
    contactEmail?: string | null;
    contactFirstName?: string | null;
    contactLastName?: string | null;
    contactPhone?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    enabled?: boolean | null;
    entityCache?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    profileImageId?: string | null;
    tenant?: TenantManagementDataEntitiesTenantRead;
    tenantId?: string;
    timezoneInfo?: string | null;
    users?: TenantManagementDataEntitiesAccountUser[] | null;
};
export type TenantManagementDataEntitiesAccountUser = {
    account?: TenantManagementDataEntitiesAccount;
    accountId?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    roles?: TenantManagementDataEntitiesRole[] | null;
    user?: TenantManagementDataEntitiesUser;
    userId?: number;
    userPrimary?: boolean | null;
};
export type TenantManagementDataEntitiesAccountUserRead = {
    account?: TenantManagementDataEntitiesAccountRead;
    accountId?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    roles?: TenantManagementDataEntitiesRoleRead[] | null;
    user?: TenantManagementDataEntitiesUserRead;
    userId?: number;
    userPrimary?: boolean | null;
};
export type TenantManagementCommonEmailTemplates =
    | 'None'
    | 'AccountSetupComplete'
    | 'Invitation'
    | 'Verification'
    | 'ChangeEmailNotification'
    | 'ChangeEmailPasswordReset'
    | 'PasswordReset'
    | 'AccountAccess'
    | 'AccountDeactivated'
    | 'MFAVerificationCode';
