import { IUserManagementInitialState } from '../interfaces/UserManagementInterface';

export const USER_MANAGEMENT_INITIAL_STATE: IUserManagementInitialState = {
    userManagementFilter: {
        limit: 20,
        offset: 0,
        name: '',
        email: '',
        accountId: 0,
        role: '',
        app: '',
        status: null,
    },
    users: [],
    feedlots: [],
    roles: [],
    apps: [],
};
