import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../Redux/Store';
import { userManagementActions } from '../reducer/userManagementSlice';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { USER_MANAGEMENT_INITIAL_STATE } from '../constants/userManagementInitialState';
import StaticOption from '../../../../../../components/common/StaticOption';
import { useTranslation } from 'react-i18next';
import {
    AppsUsingFeedlotEnum,
    CattleFinanceManagerRoleEnum,
    FeedlotManagerRoleEnum,
    ISelectStatus,
    ShippingTrackerRoleEnum,
} from '../interfaces/UserManagementInterface';
import DesktopTable from '../../../../../../components/common/DesktopTable';
import {
    useGetDataForFilterDropdowns,
    useGetQueryArgs,
    useGetUsers,
} from '../hooks/userManagementHooks';

const UserManagement = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { roles, userManagementFilter } = useAppSelector(
        state => state.userManagement,
    );

    const { apps, accounts } = useGetDataForFilterDropdowns();

    const { users, isFetching } = useGetUsers({
        apps,
        accessibleAccounts: accounts,
    });

    const handleScroll = () => {
        const canCallApi =
            users.length ===
            userManagementFilter.limit + userManagementFilter.offset;
        if (!isFetching && canCallApi) {
            const filterCopy = {
                ...userManagementFilter,
                offset:
                    userManagementFilter.limit + userManagementFilter.offset,
            };
            dispatch(userManagementActions.setUserManagementFilter(filterCopy));
        }
        return;
    };

    const statusHandler = isActive => {
        const filterCopy = {
            ...userManagementFilter,
            status: isActive,
            offset: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.offset,
            limit: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.limit,
        };

        dispatch(userManagementActions.setUserManagementFilter(filterCopy));
    };

    const filterHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const fieldName = target.name;
        const fieldValue =
            target.name === 'accountId' ? +target.value : target.value;

        const filterCopy = {
            ...userManagementFilter,
            [fieldName]: fieldValue,
            offset: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.offset,
            limit: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.limit,
        };

        if (fieldName === 'app') {
            filterCopy.accountId = 0;
            filterCopy.role = '';
            const roleEnums = {
                'Cattle Finance Manager': CattleFinanceManagerRoleEnum,
                'Feedlot Manager': FeedlotManagerRoleEnum,
                'Shipping Tracker': ShippingTrackerRoleEnum,
            };
            const roles = Object.values(roleEnums[fieldValue] || {});
            dispatch(userManagementActions.setRoles(roles));
        }
        if (
            fieldValue ===
                t(
                    'userManagement:userManagementLabels.staticOptions.selectApp',
                ) ||
            fieldValue ===
                t(
                    'userManagement:userManagementLabels.staticOptions.selectRole',
                )
        ) {
            filterCopy[fieldName] = '';
        }
        dispatch(userManagementActions.setUserManagementFilter(filterCopy));
    };

    const queryArgs = useGetQueryArgs();

    const clearFilters = () => {
        if (queryArgs.filter || queryArgs.role) {
            dispatch(
                userManagementActions.setUserManagementFilter(
                    USER_MANAGEMENT_INITIAL_STATE.userManagementFilter,
                ),
            );
        }
    };

    return (
        <>
            <div className="pt-4">
                <section className="main-content">
                    <div className="main-content-block">
                        <div className="white-content-block">
                            <Row className="filter-by">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="m-0">
                                        {t(
                                            'userManagement:userManagementLabels.staticOptions.filter',
                                        )}
                                        :
                                    </h5>
                                    <button>
                                        {t(
                                            'userManagement:userManagementLabels.staticOptions.inviteUser',
                                        )}
                                    </button>
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group className="ms-3">
                                        <Form.Label>
                                            {t(
                                                'userManagement:userManagementLabels.staticOptions.name',
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            className="text-secondary form-item border border-rounded"
                                            size="sm"
                                            placeholder={t(
                                                'userManagement:userManagementLabels.staticOptions.searchUser',
                                            )}
                                            value={userManagementFilter.name}
                                            name="name"
                                            onChange={filterHandler}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(
                                                'userManagement:userManagementLabels.staticOptions.email',
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            className="text-secondary form-item border border-rounded"
                                            size="sm"
                                            placeholder={t(
                                                'userManagement:userManagementLabels.staticOptions.searchEmail',
                                            )}
                                            value={userManagementFilter.email}
                                            name="email"
                                            onChange={filterHandler}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(
                                                'userManagement:userManagementLabels.staticOptions.apps',
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            className="text-secondary form-item border border-rounded"
                                            as="select"
                                            size="sm"
                                            value={userManagementFilter.app}
                                            name="app"
                                            onChange={filterHandler}
                                        >
                                            <option>
                                                {t(
                                                    'userManagement:userManagementLabels.staticOptions.selectApp',
                                                )}
                                            </option>
                                            {apps?.map(app => (
                                                <option key={app}>{app}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(
                                                'userManagement:userManagementLabels.staticOptions.feedlot',
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            className="text-secondary form-item border border-rounded"
                                            as="select"
                                            size="sm"
                                            value={
                                                userManagementFilter.accountId
                                            }
                                            disabled={
                                                !Object.values(
                                                    AppsUsingFeedlotEnum,
                                                ).some(
                                                    app =>
                                                        userManagementFilter.app ===
                                                        app,
                                                )
                                            }
                                            name="accountId"
                                            onChange={filterHandler}
                                        >
                                            <StaticOption
                                                options={t(
                                                    'userManagement:userManagementLabels.staticOptions.selectFeedlot',
                                                    { returnObjects: true },
                                                )}
                                            />
                                            {accounts.map(account => (
                                                <option
                                                    key={account.accountId}
                                                    value={account.accountId}
                                                >
                                                    {account.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            {t(
                                                'userManagement:userManagementLabels.staticOptions.roles',
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            className="text-secondary form-item border border-rounded"
                                            as="select"
                                            size="sm"
                                            value={userManagementFilter.role}
                                            disabled={
                                                !apps.some(
                                                    app =>
                                                        userManagementFilter.app ===
                                                        app,
                                                )
                                            }
                                            name="role"
                                            onChange={filterHandler}
                                        >
                                            <option>
                                                {t(
                                                    'userManagement:userManagementLabels.staticOptions.selectRole',
                                                )}
                                            </option>
                                            {roles?.map(role => (
                                                <option key={role}>
                                                    {role}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="d-flex align-items-end">
                                    <div
                                        className="btn-group show-toggle"
                                        data-toggle="buttons"
                                        role="group"
                                    >
                                        {(
                                            t(
                                                'userManagement:userManagementLabels.staticOptions.selectStatus',
                                                { returnObjects: true },
                                            ) as ISelectStatus[]
                                        ).map(status => (
                                            <label
                                                key={status.name}
                                                className={`btn btn-secondary py-1 ${
                                                    userManagementFilter.status ===
                                                    status.isActive
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                <input
                                                    type="radio"
                                                    name="status"
                                                    onChange={() =>
                                                        statusHandler(
                                                            status.isActive,
                                                        )
                                                    }
                                                    checked={
                                                        userManagementFilter.status ===
                                                        status.isActive
                                                    }
                                                />
                                                {status.name}
                                            </label>
                                        ))}
                                    </div>
                                </Col>
                                <Col className="d-flex align-items-end">
                                    <Button
                                        className="secondary"
                                        onClick={clearFilters}
                                    >
                                        {t(
                                            'userManagement:userManagementLabels.staticOptions.clearFilters',
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                            <DesktopTable
                                permission={false}
                                handleScroll={handleScroll}
                                tableRows={users}
                                loading={isFetching}
                                tableConstants={t(
                                    'userManagement:userManagementLabels',
                                    {
                                        returnObjects: true,
                                    },
                                )}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default UserManagement;
