import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    Row,
} from 'react-bootstrap';
import { IMoveFromHospitalPenModal } from '../../utils/Interface/HealthSection/HealthScreenInterface';
import { IUpdateAnimalPayload } from '../../utils/Interface/HospitalInterface';
import { useLanguage } from '../../commonHooks/useLocalization';
import { MoveFromHospitalPenModalConstants } from '../../utils/constants/MoveFromHospitalPenModalConstants';
import { useEffect, useState } from 'react';
import { fetchMovePens_deprecated } from '../../Redux/Actions/treatmentsActions';
import {
    fetchAnimalsHospital,
    fetchMovePens,
    moveCattleToDifferentPen,
} from '../../Redux/Actions/hospitalActions';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const {
    isCA10313On_HospitalPageUnassociatedHomePen,
    isCA11435On_EIDOnExistingLists,
    isCA12467On_ShowHospitalAndMoveToPensRations,
} = feedlotManagerFeatures;

const MoveFromHospitalPenModal = ({
    showModal,
    closeModalHandler,
    selectedAnimal,
    penFilter,
}: IMoveFromHospitalPenModal) => {
    const lotId = selectedAnimal?.lotId ?? '';
    const lotName = selectedAnimal?.lotName ?? '';
    const animalId = selectedAnimal?.animalId ?? '';
    const eid = selectedAnimal?.eid ?? '';
    const animalTag = selectedAnimal?.animalTag ?? '';
    const hospitalPen = selectedAnimal?.currentPenName ?? '';
    const hospitalPenId = selectedAnimal?.currentPenId ?? '';
    const homePenName = selectedAnimal?.homePenName ?? '';
    const homePenId = selectedAnimal?.homePenId ?? '';

    const dispatch = useAppDispatch();

    const language = useLanguage();
    const feedlotId = useFeedlotId();

    const { movePens } = useAppSelector(state => state.treatments);
    const { hospitalPens } = useAppSelector(state => state.hospital);

    const [moveToPen, setMoveToPen] = useState('');
    const [moveToPenError, setmoveToPenError] = useState(false);
    const [showHomePenMessage, setShowHomePenMessage] = useState(false);
    const [currentRationNames, setCurrentRationNames] = useState(
        [] as string[],
    );
    const [showWarning, setShowWarning] = useState(false);

    const getTodaysDateStringFormatted = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const moveDate = getTodaysDateStringFormatted();

    useEffect(() => {
        if (lotId && feedlotId) {
            if (isCA10313On_HospitalPageUnassociatedHomePen) {
                dispatch(
                    fetchMovePens(
                        feedlotId ?? 0,
                        +lotId,
                        setShowHomePenMessage,
                    ),
                );
            } else {
                dispatch(fetchMovePens_deprecated(feedlotId ?? 0, +lotId));
            }
        }
    }, [animalId, feedlotId, lotId, dispatch]);

    const onSubmit = () => {
        if (moveToPen && feedlotId) {
            const payload: IUpdateAnimalPayload = {
                animalId: +animalId,
                moveDate: new Date(moveDate).toISOString(),
                currentPenId: hospitalPenId.toString(),
                movePenId: moveToPen,
            };
            dispatch(
                moveCattleToDifferentPen(
                    feedlotId,
                    payload,
                    language.value,
                    penFilter,
                ),
            ).then(() => {
                dispatch(fetchAnimalsHospital(feedlotId, penFilter));
            });
            closeModalHandler(true);
            setMoveToPen('');
        } else {
            setmoveToPenError(true);
        }
    };

    const moveToPenHandler = (penId: string) => {
        setMoveToPen(penId);
        setmoveToPenError(false);
        if (isCA12467On_ShowHospitalAndMoveToPensRations) {
            setShowWarning(false);
            const currentRationNames = movePens.find(
                pen => pen.penId === +penId,
            )?.currentRationNames;
            setCurrentRationNames(currentRationNames ?? []);
        }
    };

    const addHomePenToMovePens =
        homePenId && !movePens.some(movePen => movePen.penId === homePenId);

    const hospitalPenCurrentRationNames =
        hospitalPens.find(pen => pen.penId === +hospitalPenId)
            ?.currentRationNames ?? [];

    const isSameRation = (): boolean => {
        const hospitalRationSet = new Set(hospitalPenCurrentRationNames);
        const movePenRationSet = new Set(currentRationNames);
        if (hospitalRationSet.size !== movePenRationSet.size) return false;
        return [...hospitalRationSet].every(item => movePenRationSet.has(item));
    };

    const handleMoveClick = () => {
        if (!moveToPen) {
            setmoveToPenError(true);
            return;
        }
        if (!isSameRation()) {
            setShowWarning(true);
        } else {
            onSubmit();
        }
    };

    const handleConfirmMove = () => {
        setShowWarning(false);
        onSubmit();
    };

    const handleCancelMove = () => {
        setShowWarning(false);
        closeModalHandler();
    };

    const truncateRationName = (rationName: string) => {
        return rationName.length > 15
            ? rationName.slice(0, 15) + '...'
            : rationName;
    };

    const getUniqueRationNames = (rationNames: string[]) => {
        return Array.from(new Set(rationNames)).join(', ');
    };

    return (
        <Modal
            show={showModal}
            onHide={() => closeModalHandler()}
            className="px-2 pt-5"
            data-size="md"
            centered={true}
        >
            <ModalHeader closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {
                            MoveFromHospitalPenModalConstants[language.value]
                                .moveFromHospitalPen
                        }
                    </h5>
                </Modal.Title>
            </ModalHeader>
            <ModalBody>
                <div className="modal-body-inner">
                    <Row className="mb-4">
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].moveDate
                                }
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={moveDate}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].lot
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="lot"
                                value={lotName}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        {eid && isCA11435On_EIDOnExistingLists && (
                            <Form.Group className="col-sm-4">
                                <Form.Label>
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].eid
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="eid"
                                    value={eid}
                                    readOnly
                                ></Form.Control>
                            </Form.Group>
                        )}
                        {(animalTag || !isCA11435On_EIDOnExistingLists) && (
                            <Form.Group className="col-sm-4">
                                <Form.Label>
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].animalId
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="animalId"
                                    value={animalTag}
                                    readOnly
                                ></Form.Control>
                            </Form.Group>
                        )}
                    </Row>
                    <Row className="mb-4">
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].hospitalPen
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="hospitalPen"
                                value={hospitalPen}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        {isCA12467On_ShowHospitalAndMoveToPensRations && (
                            <Form.Group className="col-sm-4">
                                <Form.Label>
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].hospitalCurrentRation
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="hospitalCurrentRation"
                                    value={getUniqueRationNames(
                                        hospitalPenCurrentRationNames,
                                    )}
                                    readOnly
                                ></Form.Control>
                            </Form.Group>
                        )}
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].homePen
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="homePen"
                                value={homePenName}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        {/* Remove the following Form Group while removing isCA12467On_ShowHospitalAndMoveToPensRations */}
                        {!isCA12467On_ShowHospitalAndMoveToPensRations && (
                            <Form.Group className="col">
                                <Form.Label className="me-0">
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].moveToPen
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>

                                <Form.Control
                                    onChange={e => {
                                        moveToPenHandler(e.target.value);
                                    }}
                                    name="moveToPen"
                                    required
                                    as="select"
                                    value={moveToPen}
                                    className={
                                        moveToPenError ? 'border-danger' : ''
                                    }
                                >
                                    <option value="">
                                        {
                                            MoveFromHospitalPenModalConstants[
                                                language.value
                                            ].selectPen
                                        }
                                    </option>
                                    {!isCA10313On_HospitalPageUnassociatedHomePen &&
                                        addHomePenToMovePens && (
                                            <option value={homePenId}>
                                                {homePenName}
                                            </option>
                                        )}
                                    {movePens?.map(pen => (
                                        <option
                                            key={pen.penId}
                                            value={pen.penId}
                                        >
                                            {pen.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        )}
                    </Row>
                    <Row>
                        {isCA12467On_ShowHospitalAndMoveToPensRations && (
                            <>
                                <Form.Group className="col-sm-4">
                                    <Form.Label className="me-0">
                                        {
                                            MoveFromHospitalPenModalConstants[
                                                language.value
                                            ].moveToPen
                                        }
                                    </Form.Label>
                                    <span className="necessaryFields">*</span>

                                    <Form.Control
                                        onChange={e => {
                                            moveToPenHandler(e.target.value);
                                        }}
                                        name="moveToPen"
                                        required
                                        as="select"
                                        value={moveToPen}
                                        className={
                                            moveToPenError
                                                ? 'border-danger'
                                                : ''
                                        }
                                    >
                                        <option value="">
                                            {
                                                MoveFromHospitalPenModalConstants[
                                                    language.value
                                                ].selectPen
                                            }
                                        </option>
                                        {!isCA10313On_HospitalPageUnassociatedHomePen &&
                                            addHomePenToMovePens && (
                                                <option value={homePenId}>
                                                    {homePenName}
                                                </option>
                                            )}
                                        {movePens?.map(pen => (
                                            <option
                                                key={pen.penId}
                                                value={pen.penId}
                                            >
                                                {pen.name}
                                                {'   '}
                                                {isCA12467On_ShowHospitalAndMoveToPensRations
                                                    ? truncateRationName(
                                                          getUniqueRationNames(
                                                              pen?.currentRationNames ??
                                                                  [],
                                                          ),
                                                      )
                                                    : ''}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="col-sm-4">
                                    <Form.Label>
                                        {
                                            MoveFromHospitalPenModalConstants[
                                                language.value
                                            ].currentRation
                                        }
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="currentRation"
                                        value={getUniqueRationNames(
                                            currentRationNames,
                                        )}
                                        readOnly
                                    ></Form.Control>
                                </Form.Group>
                            </>
                        )}
                    </Row>
                    <Row>
                        <Form.Group className="col-sm-12">
                            <Form.Label>
                                {isCA10313On_HospitalPageUnassociatedHomePen &&
                                    showHomePenMessage &&
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].noHomePensMessage}
                            </Form.Label>
                        </Form.Group>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                {isCA12467On_ShowHospitalAndMoveToPensRations && showWarning ? (
                    <>
                        <div className="warning-message">
                            {MoveFromHospitalPenModalConstants[
                                language.value
                            ].rationMismatchMessage(
                                getUniqueRationNames(
                                    hospitalPenCurrentRationNames,
                                ),
                                getUniqueRationNames(currentRationNames),
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={handleCancelMove}
                            className="secondary"
                        >
                            {
                                MoveFromHospitalPenModalConstants[
                                    language.value
                                ].cancel
                            }
                        </button>
                        <button type="button" onClick={handleConfirmMove}>
                            {
                                MoveFromHospitalPenModalConstants[
                                    language.value
                                ].yesMove
                            }
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            type="button"
                            onClick={() => closeModalHandler()}
                            className="secondary"
                            data-bs-dismiss="modal"
                        >
                            {
                                MoveFromHospitalPenModalConstants[
                                    language.value
                                ].cancel
                            }
                        </button>
                        <button
                            type="button"
                            onClick={
                                isCA12467On_ShowHospitalAndMoveToPensRations
                                    ? handleMoveClick
                                    : onSubmit
                            }
                        >
                            {
                                MoveFromHospitalPenModalConstants[
                                    language.value
                                ].move
                            }
                        </button>
                    </>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default MoveFromHospitalPenModal;
