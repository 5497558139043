import { FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo } from '../../../../../../Redux/Apis/FeedCallRoute/baseFeedCallRouteApi';
import { penFilterByRationDefaultIds } from '../constants/penFilterByRationDefaultOptions';

export const getIsValidPenFilterByRation = ({
    filteredPensList,
    pensList,
    penFilterByRation,
    selectedPenId,
    storagePenId,
}: {
    filteredPensList: FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo[];
    pensList: FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo[];
    penFilterByRation: string | number;
    selectedPenId: number | undefined;
    storagePenId: number | undefined;
}): boolean => {
    const penFilterByRationInvalidForFeedlot =
        !filteredPensList.length &&
        !(penFilterByRation in penFilterByRationDefaultIds);

    const penFilterByRationExcludesSelectedPenId =
        !!pensList.find(({ penId }) => penId === selectedPenId) &&
        !filteredPensList.find(({ penId }) => penId === selectedPenId);

    const penFilterByRationExcludesStoragePenId =
        !!pensList.find(({ penId }) => penId === storagePenId) &&
        !filteredPensList.find(({ penId }) => penId === storagePenId);

    return !(
        penFilterByRationInvalidForFeedlot ||
        (penFilterByRationExcludesSelectedPenId &&
            penFilterByRationExcludesStoragePenId)
    );
};
