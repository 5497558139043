import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DASHBOARD_INITIAL_STATE } from '../Constants/dashboardInitialState';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: DASHBOARD_INITIAL_STATE,
    reducers: {
        setDashboardRefreshDate: (state, action: PayloadAction<string>) => {
            state.dashboardRefreshDate = action.payload;
        },
    },
});

export const dashboardActions = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
