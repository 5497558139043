import { BaseOdataQueryApiArg } from '../rootApi';
import {
    baseUsersApi,
    GetApiTmUsersOdataApiResponse,
    TenantManagementModelsUserManagementUserModelRead,
} from './baseUsersApi';

export const usersApi = baseUsersApi
    .injectEndpoints({
        endpoints: build => ({
            getApiTmUsersOdata: build.query<
                GetApiTmUsersOdataApiResponse,
                UsersOdataQueryApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/users/odata`,
                    params: {
                        include: queryArg.include,
                        $filter: queryArg.filter,
                        $orderBy: queryArg.orderBy,
                        $top: queryArg.top,
                        $skip: queryArg.skip,
                        includeDisabled: queryArg.includeDisabled,
                        role: queryArg.role,
                    },
                }),
                serializeQueryArgs: ({ queryArgs, endpointName }) => {
                    const newQueryArgs = {
                        ...queryArgs,
                        top: undefined,
                        skip: undefined,
                    };
                    return newQueryArgs;
                },
                // Always merge incoming data to the cache entry
                merge: (currentCache, newItems, { arg }) => {
                    if (arg.skip === 0) {
                        return newItems;
                    } else {
                        currentCache.push(...newItems);
                    }
                },
                forceRefetch({ currentArg, previousArg }) {
                    return currentArg !== previousArg;
                },
                providesTags: ['Users'],
            }),
            GetUserData: build.query<
                TenantManagementModelsUserManagementUserModelRead[],
                UsersOdataQueryApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/users/odata`,
                    params: {
                        include: queryArg.include,
                        $filter: queryArg.filter,
                        $top: 1,
                    },
                }),
                providesTags: ['Users'],
            }),
        }),
        overrideExisting: true,
    })
    .enhanceEndpoints({
        addTagTypes: [],
        endpoints: {},
    });

export interface UsersOdataQueryApiArg extends BaseOdataQueryApiArg {
    include?: string;
    includeDisabled?: boolean;
    role?: string;
}
