export const MoveFromHospitalPenModalConstants = {
    en: {
        cancel: 'Cancel',
        move: 'Move',
        selectPen: 'Select Pen',
        moveToPen: 'Move to Pen',
        homePen: 'Home Pen',
        eid: 'EID',
        animalId: 'Animal ID',
        hospitalPen: 'Hospital Pen',
        moveDate: 'Move Date',
        moveFromHospitalPen: 'Move from Hospital Pen',
        lot: 'Lot',
        successMessage: 'Animal moved successfully',
        noHomePensMessage:
            'This Lot doesn\'t have any cattle in a home pen. All pens in the Feedlot are displayed in the "Move to Pen" dropdown.',
        hospitalCurrentRation: 'Hospital Current Ration',
        currentRation: 'Current Ration',
        yesMove: 'Yes, Move',
        rationMismatchMessage: (ration1: string, ration2: string) => {
            return `The Hospital Pen’s current ration (${ration1}) is different from the Move to Pen’s current ration (${ration2}). Are you sure?`;
        },
    },
    es: {
        cancel: 'Cancelar',
        move: 'Mover',
        selectPen: 'Seleccionar pluma',
        moveToPen: 'Mover a pluma',
        homePen: 'Hogar del corral',
        animalId: 'Identificación de animales',
        hospitalPen: 'Corral de Hosptial',
        moveDate: 'Fecha de mudanza',
        moveFromHospitalPen: 'Mover desde corral de hospital',
        lot: 'Lote',
        successMessage: 'Animal moved successfully',
        noHomePensMessage:
            'Este Lote no tiene ganado en un hogar del corral. Todos los corrales en el Feedlot se muestran en el menú desplegable "Mover a pluma."',
        hospitalCurrentRation: 'Ración actual del hospital',
        currentRation: 'Ración actual',
        yesMove: 'Sí, mover',
        rationMismatchMessage: (ration1: string, ration2: string) => {
            return `La ración actual del corral de hospital (${ration1}) es diferente de la ración actual de la pluma de destino (${ration2}). ¿Estás seguro?`;
        },
    },
};
