type IFilter = {
    name?: string;
    show?: string;
    ownerId?: number;
};

export const getFilterUrl = (
    filter: IFilter,
    showStatusFieldName = 'active',
    additionalConditions = '',
): string => {
    const filterName = filter?.name ? encodeURIComponent(filter.name) : '';
    let filterUrl = `&filter=contains(Name, '${filterName}')`;

    if (additionalConditions) {
        filterUrl += ` and ${additionalConditions}`;
    }

    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        const statusValue =
            showStatusFieldName === 'inactivated' ? !isActive : isActive;
        filterUrl += ` and ${showStatusFieldName} eq ${statusValue}`;
    }

    return filterUrl;
};
