import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditScaleTicketConstants } from '../constants/ScaleTicketConstants';
import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import InfoIcon from '../../../../Assets/InfoIcon';

const {
    isCA9800On_ContractsMVP,
    isCA12552On_ScaleTicketTrucingCompanyNotRequired,
} = feedlotManagerFeatures;

const CreateScaleTicketAdvanceDetailsHeaders = () => {
    const renderPriceTooltip = props => (
        <Tooltip {...props}>
            {EditScaleTicketConstants.duplicateTicketInfo}
        </Tooltip>
    );

    return (
        <div className="row">
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.ticket}
                    <span className="necessaryFields">*</span>
                    <OverlayTrigger
                        placement="top"
                        overlay={renderPriceTooltip}
                    >
                        <span className="ms-1 my-1">
                            <InfoIcon
                                className="mb-2"
                                style={{ width: '14px' }}
                            />
                        </span>
                    </OverlayTrigger>
                </Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.grossWeight}
                    <span className="necessaryFields">*</span>
                </Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.tare}
                    <span className="necessaryFields">*</span>
                </Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>{EditScaleTicketConstants.labels.net}</Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.netInTon}
                </Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.dmPercent}
                </Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.billOfLading}
                </Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.truckingCo}
                    {!isCA12552On_ScaleTicketTrucingCompanyNotRequired && (
                        <span className="necessaryFields">*</span>
                    )}
                </Form.Label>
            </div>
            <div className="col col-1">
                <Form.Label>
                    {EditScaleTicketConstants.labels.driverName}
                </Form.Label>
            </div>
            {isCA9800On_ContractsMVP && (
                <div className="col col-1">
                    <Form.Label>
                        {EditScaleTicketConstants.labels.freightRate}
                    </Form.Label>
                </div>
            )}
            <div className="col">
                <Form.Label>
                    {EditScaleTicketConstants.labels.comments}
                </Form.Label>
            </div>
        </div>
    );
};

export default CreateScaleTicketAdvanceDetailsHeaders;
