const FinancialRoutes = {
    Api: {
        CUSTOMERS: '/customers',
        CATTLE_FAXES: '/cattlefax',
        FEEDBILLS: '/feedBills',
        FEEDLOTS: '/feedlots',
        HEDGE_CONTRACTS: '/hedgeContracts',
        HEDGE_PURCHASES: '/hedgePurchases',
        HEDGE_SALES: '/hedgeSales',
        INTEREST_RATE_CHANGES: '/interestRateChanges',
        LOT_ACTIONS: '/lotActions',
        LOTS: '/lots',
        RE_LOTS: '/relots',
        SETTINGS: '/settings',
        CUSTOMERSETTINGS: 'customersettings/all',
        CUSTOMERSETTINGHISTORY: 'customersettings/history',
        DEFAULTSETTING: 'customersettings/default',
        CUSTOMERSETTING: 'customersettings',
        NEWCUSTOMERSETTING: 'customersettings/custom',
        STATEMENT: '/statement',
        PROJECT_BREAKEVEN_REPORT: 'Lots/reports/breakeven',
    },

    App: {
        HEDGES: '/hedges',
        EDIT_HEDGE: '/hedges/editHedge',
        EDIT_HEDGE_WITH_PARAM: '/hedges/editHedge/:hedgeId',
        CREATE_OPEN_HEDGE: '/createOpenHedge',
        CREATE_CLOSE_HEDGE: '/createCloseHedge',

        CREATE_OWNER: '/createOwner',

        CUSTOMER_CLOSEOUT: '/lots/customerCloseout',
        CUSTOMER_CLOSEOUT_WITH_PARAM: '/lots/customerCloseout/:lotId',
        LENDER_REPORT: '/lots/lenderReport',
        OWNER_REPORT: '/lots/ownerReport',
        SHAWNA_SPREADSHEET: '/lots/lotCashFlow',

        FEEDLOTS: '/feedlots',
        CREATE_FEEDLOT: '/createFeedlot',
        FEEDLOT: '/feedlots/feedlot',
        FEEDLOT_WITH_PARAM: '/feedlots/feedlot/:feedlotId',

        LOTS: '/lots',
        CREATE_LOT: '/createLot',
        LOT: '/lots/lot',
        LOT_WITH_PARAM: '/lots/lot/:lotId',
        LOT_BALANCE_REPORT: '/lots/lotBalancesReport',
        LOT_SUMMARY_REPORT: '/lots/lotSummaryReport',
        QUARTERLY_REPORT: '/lots/quarterlyReport',
        PROJECT_BREAKEVEN_REPORT: '/lots/projectedbreakevenreport',

        CREATE_RE_LOT: '/createReLot',
        EDIT_RE_LOT: '/lots/editReLot',
        EDIT_RE_LOT_WITH_PARAM: 'lots/editReLot/:reLotId',

        FEEDBILLS: '/feedBills',
        CREATE_FEEDBILL: '/createFeedBill',
        FEEDBILL: '/feedBill',
        EDIT_FEEDBILL: '/feedBill/editFeedBill',
        EDIT_FEEDBILL_WITH_PARAM: '/feedBill/editFeedBill/:feedBillId',

        CREATE_CATTLE_PURCHASE: '/createCattlePurchase',
        EDIT_CATTLE_PURCHASE: '/lots/editCattlePurchase',
        EDIT_CATTLE_PURCHASE_WITH_PARAM:
            '/lots/editCattlePurchase/:cattlePurchaseId',

        CREATE_CATTLE_SALE: '/createCattleSale',
        EDIT_CATTLE_SALE: '/lots/editCattleSale',
        EDIT_CATTLE_SALE_WITH_PARAM: '/lots/editCattleSale/:cattleSaleId',

        CREATE_CATTLE_WEIGHT: '/lots/createCattleWeight',
        EDIT_CATTLE_WEIGHT: '/lots/editCattleWeight',
        EDIT_CATTLE_WEIGHT_WITH_PARAM: '/lots/editCattleWeight/:cattleWeightId',

        CREATE_CATTLE_DEAD: '/lots/createCattleDead',
        EDIT_CATTLE_DEAD: '/lots/editCattleDead',
        EDIT_CATTLE_DEAD_WITH_PARAM: '/lots/editCattleDead/:cattleDeadId',

        HEDGE_SALE: '/hedgeSales',
        HEDGE_CONTRACTS: '/hedgecontracts',

        STATEMENTS: '/statements',

        CATTLE_FAXES: '/cattlefaxes',

        INTEREST_RATE_CHANGES: '/interestRateChanges',

        SETTINGS: '/settings',
    },
};

export default FinancialRoutes;
