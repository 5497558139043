import { AppStartListening } from '../../../../../../Redux/listenerMiddleware';
import { commonActions } from '../../../../../../Redux/Reducer/common';
import StorageService from '../../../../../../Services/storage/storage.service';
import { StorageKeys } from '../../../../../../Services/storage/storageKeys';
import Constants from '../../../../../../utils/Constants';
import { penFilterByRationDefaultIds } from '../constants/penFilterByRationDefaultOptions';
import { getFilteredFeedCallPensList } from '../helpers';
import { feedCallPageActions } from '../slices/feedCallPageSlice';
import { getIsValidPenFilterByRation } from './helpers';

export const addFeedCallListenerMiddleware = (
    startAppListening: AppStartListening,
) => {
    startAppListening({
        actionCreator: feedCallPageActions.setSelectedPenId,
        effect: (_, listenerApi) => {
            const { selectedPenId } = listenerApi.getState().feedCallPage;
            if (selectedPenId) {
                new StorageService().setValue(
                    StorageKeys.FeedCallPageSelectedPenId,
                    selectedPenId,
                    Constants.Storage.LOCAL,
                );
            }
        },
    });

    startAppListening({
        actionCreator: feedCallPageActions.setPensList,
        effect: (_, listenerApi) => {
            const { penFilterByRation, pensList } =
                listenerApi.getState().feedCallPage;
            const filteredPensList = getFilteredFeedCallPensList(
                pensList,
                penFilterByRation,
            );

            listenerApi.dispatch(
                feedCallPageActions.setFilteredPensList(filteredPensList),
            );
        },
    });

    startAppListening({
        actionCreator: feedCallPageActions.setFilteredPensList,
        effect: (_, listenerApi) => {
            const {
                selectedPenId,
                filteredPensList,
                pensList,
                penFilterByRation,
            } = listenerApi.getState().feedCallPage;

            if (
                !pensList.length ||
                filteredPensList.find(pen => pen.penId === selectedPenId)
            ) {
                return;
            }

            const storagePenId: number | undefined =
                new StorageService().getValue(
                    StorageKeys.FeedCallPageSelectedPenId,
                    Constants.Storage.LOCAL,
                );

            const isValidPenFilterByRation = getIsValidPenFilterByRation({
                filteredPensList,
                pensList,
                penFilterByRation,
                selectedPenId,
                storagePenId,
            });

            if (!isValidPenFilterByRation) {
                listenerApi.dispatch(
                    feedCallPageActions.setPenFilterByRation(
                        penFilterByRationDefaultIds.all,
                    ),
                );
                return;
            }

            let newSelectedPenId: number | undefined = undefined;
            let newPenFilterByRation: string | null = null;
            if (
                storagePenId &&
                filteredPensList.find(pen => pen.penId === storagePenId)
            ) {
                newSelectedPenId = storagePenId;
            } else if (pensList.find(pen => pen.penId === selectedPenId)) {
                newSelectedPenId = selectedPenId;
                newPenFilterByRation = penFilterByRationDefaultIds.all;
            } else if (
                storagePenId &&
                pensList.find(pen => pen.penId === storagePenId)
            ) {
                newSelectedPenId = storagePenId;
                newPenFilterByRation = penFilterByRationDefaultIds.all;
            } else {
                newSelectedPenId = filteredPensList?.[0]?.penId;
            }

            if (
                newPenFilterByRation &&
                newPenFilterByRation !== penFilterByRation
            ) {
                listenerApi.dispatch(
                    feedCallPageActions.setPenFilterByRation(
                        newPenFilterByRation,
                    ),
                );
            }

            if (newSelectedPenId !== selectedPenId) {
                listenerApi.dispatch(
                    feedCallPageActions.setSelectedPenId(newSelectedPenId),
                );
            }
        },
    });

    startAppListening({
        actionCreator: feedCallPageActions.setPenFilterByRation,
        effect: (_, listenerApi) => {
            const { pensList, penFilterByRation, selectedPenId } =
                listenerApi.getState().feedCallPage;

            new StorageService().setValue(
                StorageKeys.FeedCallPagePenFilterByRation,
                penFilterByRation,
                Constants.Storage.LOCAL,
            );

            const filteredPensList = getFilteredFeedCallPensList(
                pensList,
                penFilterByRation,
            );

            if (
                !filteredPensList.find(({ penId }) => penId === selectedPenId)
            ) {
                listenerApi.dispatch(
                    feedCallPageActions.setSelectedPenId(
                        filteredPensList[0]?.penId,
                    ),
                );
            }

            listenerApi.dispatch(
                feedCallPageActions.setFilteredPensList(filteredPensList),
            );
        },
    });

    startAppListening({
        actionCreator: commonActions.setSelectedFeedlot,
        effect: (_, listenerApi) => {
            const newFlid = listenerApi.getState().common.selectedFeedlot.id;
            const originalFlid =
                listenerApi.getOriginalState().common.selectedFeedlot.id;

            if (newFlid !== originalFlid) {
                new StorageService().removeKey(
                    StorageKeys.FeedCallPageSelectedPenId,
                    Constants.Storage.LOCAL,
                );
            }
        },
    });
};
