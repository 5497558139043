import { Button, Spinner } from 'react-bootstrap';
import { IProtectedSpinnerButton } from '../../utils/Interface/CommonInterface';

const ProtectedSpinnerButton = ({
    disabled = false,
    variant = 'primary',
    showSpinner = false,
    className = '',
    buttonContent = '',
    hidden = false,
    type = 'button',
    size,
    onClick,
}: IProtectedSpinnerButton) => (
    <Button
        type={type}
        variant={variant}
        className={
            showSpinner
                ? `loading-button ${className || ''}`
                : `${className || ''}`
        }
        hidden={hidden}
        onClick={onClick}
        disabled={disabled}
        size={size}
    >
        <span className="pt-2 align-items-center justify-content-center">
            {showSpinner && <Spinner size="sm" />} {buttonContent}
        </span>
    </Button>
);

export default ProtectedSpinnerButton;
