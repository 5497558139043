import { useEffect } from 'react';
import { IGenericSetState } from '../../../../utils/Interface/CommonInterface';

const useUpdateOnSelected = (
    selected: string | number | undefined,
    options: object[],
    setInputValue: IGenericSetState<string>,
    nameKey: string,
    valueKey: string | number,
    allowZeroValue?: boolean,
) => {
    useEffect(() => {
        if (selected || (allowZeroValue && selected === 0)) {
            const selectedOption = options.find(
                option => option[valueKey]?.toString() === selected?.toString(),
            );
            if (selectedOption) {
                setInputValue(selectedOption[nameKey]);
            }
        } else {
            setInputValue('');
        }
    }, [selected, options, nameKey, valueKey, setInputValue]);
};

export default useUpdateOnSelected;
