import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMMON_INITIAL_STATE } from '../Constants/commonInitialState';
import {
    IFetchFees,
    ISelectedLanguage,
    IToastMessage,
    IWindowSize,
    sideNavTabs,
} from '../../utils/Interface/CommonInterface';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../Apis/FMFeedlot/baseFMFeedlotApi';
import { isEqual } from 'lodash';

const commonSlice = createSlice({
    name: 'common',
    initialState: COMMON_INITIAL_STATE,
    reducers: {
        fetchWindowSize: (state, action: PayloadAction<IWindowSize>) => {
            state.windowSize = action.payload;
        },
        setActive: (state, action: PayloadAction<boolean>) => {
            state.isNavActive = action.payload;
        },
        toastMessage: (state, action: PayloadAction<IToastMessage>) => {
            state.toastMessage = action.payload;
        },
        fetchFeeTypes: (state, action: PayloadAction<IFetchFees[]>) => {
            state.feeTypes = action.payload;
        },
        setFeedlotToUpdate: (
            state,
            action: PayloadAction<
                Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>
            >,
        ) => {
            if (!isEqual(state.selectedFeedlot, action.payload)) {
                state.feedlotToUpdate = action.payload;
            }
        },
        setSelectedFeedlot: (
            state,
            action: PayloadAction<
                Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>
            >,
        ) => {
            state.selectedFeedlot = action.payload;
            state.feedlotToUpdate = COMMON_INITIAL_STATE.feedlotToUpdate;
        },
        setSelectedLot: (state, action: PayloadAction<string>) => {
            state.selectedLot = action.payload;
        },
        setShowSupportModal: (state, action: PayloadAction<boolean>) => {
            state.showSupportModal = action.payload;
        },
        setSelectedLanguage: (
            state,
            action: PayloadAction<ISelectedLanguage>,
        ) => {
            state.selectedLanguage = action.payload;
        },
        setSelectedSideNavTab: (state, action: PayloadAction<sideNavTabs>) => {
            state.currentSelectedTab = action.payload;
        },
    },
});

export const {
    fetchWindowSize,
    setActive,
    toastMessage,
    fetchFeeTypes,
    setFeedlotToUpdate,
    setSelectedFeedlot,
    setSelectedLot,
    setShowSupportModal,
    setSelectedLanguage,
    setSelectedSideNavTab,
} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
export const commonActions = commonSlice.actions;
