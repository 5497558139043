import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLoading } from '../../commonHooks/useLoading';
import { useAppDispatch } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { mixingBinConstants } from '../../utils/constants/MixingBinConstants';
import {
    IMixingBinDetail,
    IMixingBinDetailsError,
    IMixingBinModal,
} from '../../utils/Interface/MixingBinInterface';
import {
    createMixingBin,
    fetchInactiveBinInfo,
    updateMixingBinDetail,
} from '../../Redux/Actions/mixingBinActions';
import FullPageLoader from '../common/FullPageLoader';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';

const MixingBinModal = ({
    showModal,
    closeModalWithChanges,
    editMixingBinDetails,
}: IMixingBinModal) => {
    const [mixingBinDetail, setMixingBinDetail] =
        useState<IMixingBinDetail>(editMixingBinDetails);
    const [mixingBinDetailsError, setMixingBinDetailsError] =
        useState<IMixingBinDetailsError>(
            mixingBinConstants.initialBinDetailsError,
        );
    const [inActiveMixingBin, setInActiveMixingBin] =
        useState<IMixingBinDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const validateMixingBinDetails = (): boolean => {
        let isValid = true;
        const { name, capacity } = mixingBinDetail;
        const updatedMixingBinDetailErrors = {
            name: !name,
            capacity: !capacity,
        };
        setMixingBinDetailsError(updatedMixingBinDetailErrors);
        if (Object.values(updatedMixingBinDetailErrors).includes(true)) {
            isValid = false;
        }
        return isValid;
    };

    const handleActivateMixingBin = async () => {
        if (feedlotId) {
            const inactiveMixingBinInfo = await dispatch(
                fetchInactiveBinInfo(feedlotId, mixingBinDetail.name?.trim()),
            );
            if (inactiveMixingBinInfo) {
                setInActiveMixingBin(inactiveMixingBinInfo[0]);
                openActivateModal();
            }
        }
    };

    const handleUpdateMixingBinDetail = (): void => {
        const isValid = validateMixingBinDetails();
        if (feedlotId && isValid) {
            dispatch(
                updateMixingBinDetail(
                    feedlotId,
                    mixingBinDetail,
                    undefined,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateMixingBin,
                ),
            );
        }
    };

    const handleCreateMixingBin = (): void => {
        const isValid = validateMixingBinDetails();
        if (feedlotId && isValid) {
            dispatch(
                createMixingBin(
                    feedlotId,
                    mixingBinDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateMixingBin,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setMixingBinDetail(prevMixingBinDetail => ({
                ...prevMixingBinDetail,
                [fieldName]:
                    fieldName === 'capacity' ? Number(fieldValue) : fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated && feedlotId) {
            if (inActiveMixingBin) {
                const inActiveMixingBinCopy = {
                    ...inActiveMixingBin,
                    active: true,
                };
                dispatch(
                    updateMixingBinDetail(
                        feedlotId,
                        inActiveMixingBinCopy,
                        undefined,
                        closeModalWithChanges,
                        setLoading,
                    ),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={mixingBinDetail.name?.trim()}
                item={mixingBinConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editMixingBinDetails.name
                            ? `${mixingBinConstants.editMixingBin} ${editMixingBinDetails?.name}`
                            : mixingBinConstants.createNewMixingBins.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <div className="col-5 mb-4">
                            <Form.Label className="text-secondary">
                                {
                                    mixingBinConstants.createNewMixingBins.form
                                        .name.label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    mixingBinDetailsError.name ? 'danger' : ''
                                }`}
                                value={mixingBinDetail?.name}
                                name={
                                    mixingBinConstants.createNewMixingBins.form
                                        .name.name
                                }
                                placeholder={
                                    mixingBinConstants.createNewMixingBins.form
                                        .name.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    mixingBinConstants.createNewMixingBins.form
                                        .name.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-5 mb-4">
                            <Form.Label className="text-secondary">
                                {
                                    mixingBinConstants.createNewMixingBins.form
                                        .capacity.label
                                }
                            </Form.Label>
                            <span className="necessaryFields">*</span>
                            <Form.Control
                                className={`text-secondary border-${
                                    mixingBinDetailsError.capacity
                                        ? 'danger'
                                        : ''
                                }`}
                                value={mixingBinDetail?.capacity}
                                name={
                                    mixingBinConstants.createNewMixingBins.form
                                        .capacity.name
                                }
                                placeholder={
                                    mixingBinConstants.createNewMixingBins.form
                                        .capacity.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'number',
                                    )
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="custom-checkbox col-5 ">
                        <input
                            type="checkbox"
                            checked={mixingBinDetail.active}
                            className="mx-2"
                            onChange={e =>
                                handleDetailChange(
                                    !mixingBinDetail.active,
                                    'active',
                                )
                            }
                        />
                        <label className="mx-2">
                            {FmSettingsConstant.active.label}
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editMixingBinDetails.name
                                ? handleCreateMixingBin
                                : handleUpdateMixingBinDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MixingBinModal;
