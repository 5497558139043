import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLoading } from '../../commonHooks/useLoading';
import { useAppDispatch } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { techConstants } from '../../utils/constants/TechConstants';
import { ITechDetail, ITechModal } from '../../utils/Interface/TechInterface';
import {
    createTech,
    fetchInactiveTechInfo,
    updateTechDetail,
} from '../../Redux/Actions/techActions';
import FullPageLoader from '../common/FullPageLoader';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { TECH_INITIAL_STATE } from '../../Redux/Constants/techInitialState';

const TechModal = ({
    showModal,
    closeModalWithChanges,
    editTechDetails,
    showActiveField,
}: ITechModal) => {
    const [techDetail, setTechDetail] = useState<ITechDetail>(editTechDetails);
    const [techDetailNameError, setTechDetailNameError] =
        useState<boolean>(false);
    const [inActiveTech, setInActiveTech] = useState<ITechDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const validateTechDetails = (): boolean => {
        if (!techDetail.name) {
            setTechDetailNameError(true);
            return false;
        }
        return true;
    };

    const handleActivateTech = async () => {
        if (feedlotId && techDetail.name) {
            const inactiveTechInfo = await dispatch(
                fetchInactiveTechInfo(feedlotId, techDetail.name.trim()),
            );
            if (inactiveTechInfo) {
                setInActiveTech(inactiveTechInfo[0]);
                openActivateModal();
            }
        }
    };

    const handleUpdateTechDetail = (): void => {
        const isValid = validateTechDetails();
        if (feedlotId && isValid) {
            dispatch(
                updateTechDetail(feedlotId, techDetail, {
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTech,
                }),
            );
        }
    };

    const handleCreateTech = (): void => {
        const isValid = validateTechDetails();
        if (feedlotId && isValid) {
            dispatch(
                createTech(
                    feedlotId,
                    techDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTech,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setTechDetail(prevTechDetail => ({
                ...prevTechDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated && feedlotId) {
            if (inActiveTech) {
                const inActiveTechCopy = {
                    ...inActiveTech,
                    active: true,
                };
                dispatch(
                    updateTechDetail(feedlotId, inActiveTechCopy, {
                        closeModalWithChanges,
                        setLoading,
                    }),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (!showModal) {
            setTechDetail({ ...TECH_INITIAL_STATE.createTechInitial });
        }
    }, [showModal]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={techDetail.name?.trim()}
                item={techConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editTechDetails.name
                            ? `${techConstants.editTech} ${editTechDetails?.name}`
                            : techConstants.createNewTechs.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <div className="col-5 mb-4">
                            <Form.Label className="text-secondary">
                                {techConstants.createNewTechs.form.name.label}
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    techDetailNameError ? 'danger' : ''
                                }`}
                                value={techDetail?.name}
                                name={
                                    techConstants.createNewTechs.form.name.name
                                }
                                placeholder={
                                    techConstants.createNewTechs.form.name
                                        .placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    techConstants.createNewTechs.form.name
                                        .maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        {showActiveField && (
                            <div className="custom-checkbox col-5 ">
                                <input
                                    type="checkbox"
                                    checked={techDetail.active}
                                    className="mx-2"
                                    onChange={e =>
                                        handleDetailChange(
                                            !techDetail.active,
                                            'active',
                                        )
                                    }
                                />
                                <label className="mx-2">
                                    {FmSettingsConstant.active.label}
                                </label>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editTechDetails.name
                                ? handleCreateTech
                                : handleUpdateTechDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TechModal;
