import dayjs from '../utils/dayjs';

export type date = dayjs.Dayjs;
//eslint-disable-next-line no-restricted-syntax
export type dateForThirdPartyComponents = Date; //only to be used for third party components that REQUIRE native JavaScript Date

export type dateFormat =
    | 'MM-DD-YYYY'
    | 'YYYY-MM-DD'
    | 'MM/DD/YYYY'
    | 'MMMM DD'
    | 'MM-DD-YYYY hh:mm:ss a'
    | 'HH:mm:ss'
    | 'MM-DD-YYYY HH:mm:ss'
    | 'MM/DD'
    | 'MMMM D, YYYY h:mm A z';

export const formatDate = (
    date: date | string | null | undefined,
    dateFormat: dateFormat = 'MM-DD-YYYY',
): string => {
    if (!date) return '';
    return dayjs(date).format(dateFormat);
};

export const currentDate = (dateFormat: dateFormat = 'MM-DD-YYYY'): string => {
    return dayjs().format(dateFormat);
};

export const thisDateWithinCutoff = (
    thisDate: date,
    cutoffDate: date,
): boolean => thisDate.isSameOrBefore(cutoffDate, 'date');
